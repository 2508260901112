<template>
  <div>
		<div class="d-flex"
			style="overflow-x: scroll;">
			<div class="py-1 px-3 flex-fill "
				v-for="(tab, index) in arr_tab"
				:key="index"
				style="cursor: pointer; min-width: 12rem;"
				@click="onTabClicked(index)"
				:class="{'tab-quiz-question-selected': selected_tab1 == index, 'tab-disabled': selected_tab1 != index, 'mr-1': index < arr_tab.length - 1, 'ml-1': index > 0,}">
				<p class="m-0 text-center">{{ tab.name }}</p>
			</div>
		</div>

		<div class="mt-3">
			<p class="m-0">To change total question, you can edit at General Info tab</p>
		</div>

		<div class="mt-3">
			<div v-for="(tab, index) in arr_tab" :key="index">
				<ListQuestionItem  v-if="selected_tab1 == index"
					:is_change_tab="is_change_tab"
					:is_change_tab1="is_change_tab1"
					:index="index"
					:tab_type="tab_type"
					:selected_tab="selected_tab1"
					:num_data="num_data"
					:num_question_per_page="num_question_per_page"
					:page="index + 1"
					:is_submit="is_submit"
					:quiz_data="quiz_data"
					@onValidate="(index_tab, message, obj) => onValidate(index_tab, message, obj, index)"/>
			</div>
		</div>
  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";
import ListQuestionItem from "@/pages/competition/component/quiz/edit/list_question_item";

export default {
  props: ["quiz_data", 'selected_tab', 'is_submit', 'is_change_tab', 'tab_type', ],
  components: {
		ListQuestionItem: ListQuestionItem,
	},
  data() {
    return {
      base: null,
			selected_tab1: 0,
			num_question: 0,
			num_data: 10,
			num_question_per_page: 10,
			arr_tab: [],
			selected_index_tab: 0,
			is_change_tab1: false,
			allow_change_tab: false,
    };
  },
	watch: {
		quiz_data(val){
			this.manage_tab()
		},
		selected_tab(val){
			this.selected_tab1 = 0
			this.manage_tab()
		},
		allow_change_tab(val){
			if(val){
				this.selected_tab1 = this.selected_index_tab
				this.num_question_per_page = this.arr_tab[this.selected_index_tab].length_index
				this.allow_change_tab = false
			}
		},
	},
  async mounted() {
    this.base = new Base();
		this.manage_tab()
  },
  methods: {
		manage_tab(){
			if(this.quiz_data.id != null){
				this.num_question = this.quiz_data.num_question
				var arr_tab = []
				for(let x = 0; x < this.quiz_data.num_question; x += this.num_data){
					var tab = {
						id: x,
						start_index: x,
						end_index: x + this.num_data < this.quiz_data.num_question ? (x + this.num_data - 1) : this.quiz_data.num_question - 1,
					}
					tab.name = 'Question ' + (tab.start_index != tab.end_index ? (tab.start_index + 1) + ' - ' + (tab.end_index + 1) : tab.start_index + 1)
					tab.length_index = tab.end_index - tab.start_index + 1
					arr_tab.push(tab)
				}

				if(arr_tab.length > 0)
					this.num_question_per_page = arr_tab[0].length_index
				this.arr_tab = arr_tab
			}
		},
		onTabClicked(index){
			this.is_change_tab1 = true
			this.selected_index_tab = index
		},
		save_temp_question_pagination(message, obj, index, with_emit = true){
			var tab = this.arr_tab[index]
			var arr_question = JSON.parse(JSON.stringify(this.quiz_data.question))
			var counter = 0

			for(let x = tab.start_index; x <= tab.end_index; x++){
				arr_question[x] = obj.arr_question[counter]
				counter++
			}

			if(with_emit)
				this.$emit('onValidate', message, {
					question: arr_question,
				})
		},
		onValidate(index_tab, message, obj, index){
			var context = this
			if(this.is_change_tab1){
				this.is_change_tab1 = false
				if(message != '')
					context.base.show_error(message)
				else{
					context.allow_change_tab = true
					context.save_temp_question_pagination(message, obj, index, false)
				}
			}
			else
				this.save_temp_question_pagination(message, obj, index)
		},
	},
};
</script>

<style lang="scss">
	.tab-quiz-question-selected{
		background-color: $warning;
		color: $black3;
	}
</style>

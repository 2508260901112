<template>
  <div class="px-5 w-100 certificate-container">
    <div class="container ">
      <p class="m-0 text-primary text-center supporting-title">{{ $t("our_activity_title") }}</p>

      <div class="mt-5">
        <div v-for="(activity, index) in arr_activity" :key="index" class="row mb-5">
          <div class="col-12 col-md-6">
            <div class="position-relative">
              <img :src="activity.image" class="w-100" :alt="'activity image ' + (index + 1)">
              <!-- <div class="position-absolute bg-our-activity-image w-100 h-75 d-flex align-items-end" style="bottom: 0;">
                <p class="m-0 text-primary supporting-title ml-5 mb-4">{{ activity.name }}</p>
              </div> -->
            </div>
          </div>
          <div class="col-12 col-md-6 mt-3 mt-md-0 position-relative">
            <p class="m-0 our-activity-content text-justify">{{ activity.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import moment from 'moment';


// import Activity1 from '@/assets/our_activity1.png';
// import Activity2 from '@/assets/our_activity2.png';
// import Activity3 from '@/assets/our_activity3.png';

import Activity2 from '@/assets/StudentOpen_Home_Competition.png';
import Activity1 from '@/assets/StudentOpen_Home_Medal Ceremony.png';
import Activity3 from '@/assets/StudentOpen_Home_Coach_meeting.png';

export default {
  components: {
  },
  props: ['articleTitle1Flag', 'articleTitle2Flag', ],
  data(){
    return{
      base: null,
      total_player: 6312,
      total_coach: 1488,
      total_school: 150,
      total_sport_club: 243,
      arr_activity: [
        {
          id: "1",
          image: Activity3,
          name: 'Coach Meeting',
          content: "Activities that bring together coaches with competition managers and referee leaders to inform them of match rules and activity concepts so that all parties are on the same understanding to make the activity a success. This activity is also supported by other activities such as training regarding sports injuries and sports nutrition so that coaches get good knowledge for their team and players.",
        },
        {
          id: "1",
          image: Activity2,
          name: 'Competition',
          content: "The competition uses a league system, where each category will be divided into groups consisting of a maximum of 8 teams. Each team will compete a maximum of 7x to get points resulting from each winning calculation in each match. For individual sports, the competition uses a knockout system. Each player will compete according to the results of each match and continue to advance to the final round.",
        },
        {
          id: "1",
          image: Activity1,
          name: 'Medal Ceremony',
          content: "The closing event will be attended by all participants from all sports held at STUDENT OPEN to be given awards to the winners in each sports category. Besides group winners, there are also contests for man of the match, top scorer, and best school. To support the main event (award winners), there are supporting events such as opening booths for all official partners and sponsors as well as participating schools as a forum to introduce the school and company products to the public.",
        },
      ],
    }
  },
  watch: {
    isLoading(val){
      this.$emit("onLoading", val, 2)
    },
  },
  created(){
    this.base = new Base()

    // this.get_article()
  },
  methods:{
    async get_article(){
      var response = await this.base.request(this.base.url_api + "/article/all?num_data=3&is_publish=1&type=newest&rel_type=simple")
      // this.$emit('onCompleteRequest', 3)

      if(response != null){
        if(response.status === "success"){
          for(let article of response.data){
            article.image = this.base.host + "/media/article?file_name=" + article.file_name
            article.date = article.publish_at != null ? moment(article.publish_at, "YYYY-MM-DD") : ''
          }
          // console.log(response.data)
          this.arr_article = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-right: .5rem;
  }
  .article-second{
    padding-left: .5rem;
  }
}
@media only screen and (min-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-left: .5rem;
  }
  .article-second{
    padding: 0 .5rem;
  }
}

.bg-our-activity-image{
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), $yellow3);
}
.supporting-title{
  font-family: 'montserrat-bold';
  font-size: 2rem;
}
.check-certificate-detail{
  font-family: 'montserrat-regular';
}
.supporting_hr{
  width: 1px;
  border: 1px solid $yellow1;
}
.our-activity-content{
  font-family: 'montserrat-light';
  color: $gray20;
}
.home-article-title1-enter-active, .home-article-title1-leave-active{
  transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
  transform: translateY(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
  transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
  transform: translateY(-10rem);
  opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
  transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
  transform: translateX(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
</style>

<template>
  <div>

		<p class="m-0">General Information</p>

		<div class="d-flex align-items-center mt-3">
			<div class="quiz-question-item-label">
				<p class="m-0">Title Quiz</p>
				<p class="m-0">(Max. 100 Character)</p>
			</div>
			<div class="quiz-question-item-input">
				<input type="text" class="form-control w-auto" v-model="title"/>
			</div>
		</div>

		<div class="d-flex align-items-center mt-3">
			<div class="quiz-question-item-label">
				<p class="m-0" style="width: 15rem">Total Quiz</p>
			</div>
			<div class="quiz-question-item-input">
				<input type="text" class="form-control w-auto" v-model="num_question"/>
			</div>
		</div>

		<div class="d-none align-items-center mt-3">
			<div class="quiz-question-item-label">
				<p class="m-0" style="width: 15rem">Timer</p>
			</div>
			<div class="d-flex align-items-center quiz-question-item-input">
				<input type="text" class="form-control" v-model="hour"/>
				<p class="m-0 mx-1">:</p>
				<input type="text" class="form-control" v-model="minute"/>
			</div>
		</div>

  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";

export default {
  props: ["quiz_data", 'selected_tab', 'is_change_tab', ],
  components: {},
  data() {
    return {
      base: null,
			title: '',
			num_question: '0',
			hour: '0',
			minute: '0',
			second: '0',
			timer: momentTZ.duration(1, 'h')
    };
  },
	watch: {
		title(val){
			if(val != null)
				this.title = val.substring(0, 100)
		},
		quiz_data(val){
			this.fill_data()
		},
		is_change_tab(val){
			if(val)
				this.validate()
		},
		num_question(val){
			this.num_question = this.base.to_currency_format(val)
		},
		hour(val){
			this.hour = this.base.to_currency_format(val, 100)
		},
		minute(val){
			this.minute = this.base.to_currency_format(val, 59)
		},
		second(val){
			this.second = this.base.to_currency_format(val, 59)
		},
	},
  async mounted() {
    this.base = new Base();
		this.fill_data()
  },
  methods: {
		fill_data(){
			if(this.quiz_data.id != null){
				var timer = momentTZ.duration(this.quiz_data.quiz_timer, 's')

				this.title = this.quiz_data.title
				this.num_question = (this.quiz_data.num_question != null ? this.quiz_data.num_question : this.quiz_data.question.length).toLocaleString(this.base.locale_string)
				this.hour = timer.hours().toLocaleString(this.base.locale_string)
				this.minute = timer.minutes().toLocaleString(this.base.locale_string)
				this.second = timer.seconds().toLocaleString(this.base.locale_string)
			}
		},
		validate(){
			var message = ''
			if(this.title == '')
				message = 'Title is Empty'
			else if(this.num_question == '0')
				message = 'Total Quiz is Empty'

			this.$emit('onValidate', message, {
				title: this.title,
				num_question: this.base.str_to_double(this.num_question),
				quiz_timer: momentTZ.duration({
					seconds: this.second,
					minutes: this.minute,
					hours: this.hour,
				}).asSeconds(),
			})
		},
	},
};
</script>

<style lang="scss">
</style>

<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail :title="competition_data.category_sport != null && competition_data.category_sport.name != null ? competition_data.category_sport.name : ''" />
    </div>
    <CompetitionTab :competition_data="competition_data"/>

    <div class="my-5 pb-5 container">
      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t('school') }}</p>

        <div class="py-3">
          <div class="form-group ">
            <select class="form-control"
              style="font-size: 0.75rem; height: 2.5rem; width: auto; background-position: right .75rem center;" v-model="type">
              <option value="club">Sport Club</option>
              <option value="school">Academy</option>
            </select>
          </div>

          <div v-if="arr_school.length > 0">
            <table class="table table-member table-striped">
              <tr v-for="(school, index) in arr_school" :key="index">
                <td class="w-50">
                  <div class="d-flex align-items-center">
                    <img :src="school.image" style="width: 2.5rem;" :alt="'school image ' + (index + 1)"/>
                    <span class="pl-3 font-weight-bold" style="color: #015057;">
                      {{ school.name }}
                    </span>
                  </div>
                </td>
                <!-- <td class="align-middle">{{ school.category.name }}</td>
                <td class="align-middle">{{ school.province.name }}</td> -->
                <td class="align-middle w-50">{{ school.city != null ? school.city.name : '-' }}</td>
                <td class="align-middle actionDiv" style="width: 1%; white-space: nowrap;">
                  <!-- <button type="button" class="btn btn-secondary" data-container="body" data-trigger="focus" data-toggle="popover" data-placement="left" data-content="Top popover">
                    Popover on top
                  </button> -->
                  <div class="position-relative">
                    <div class="popover-container position-absolute py-2 px-2 rounded" style="left: -7rem; top: -.5rem;" v-show="school.action_selected">
                      <a target="_blank" :href="school.phone != null ? 'tel:' + school.phone : '-'">
                        <img src="@/assets/member_call.png" class="popover-item-image" :alt="'call image ' + (index + 1)">
                      </a>
                      <a target="_blank" :href="school.phone != null ? 'https://wa.me/' + school.phone : '-'">
                        <img src="@/assets/member_chat.png" class="popover-item-image" :alt="'chat image ' + (index + 1)">
                      </a>
                      <a target="_blank" :href="school.email != null ? 'mailto:' + school.email : '-'">
                        <img src="@/assets/member_mail.png" class="popover-item-image" :alt="'mail image ' + (index + 1)">
                      </a>
                    </div>

                    <a @click="onActionClicked(index)" rel="popover" role="button" tabindex="1">
                      <img width="24" height="24" src="https://img.icons8.com/ios/24/015057/menu-2.png" alt="menu-2" />
                    </a>
                  </div>
                  <!-- <img src="@/assets/member_call.png" style="height: 1.25rem;"> -->
                  <!-- <a href="#" class="ml-3">
                    <img src="@/assets/member_chat.png" style="height: 1.25rem;">
                  </a>
                  <a href="#" class="ml-3">
                    <img src="@/assets/member_mail.png" style="height: 1.25rem;">
                  </a> -->
                </td>

                <!-- <div class="" hidden>
                  <div data-name="popover-content">
                    <div class="input-group">
                      <input type="text" class="form-control form-control-sm" placeholder="Search" name="search">
                      <div class="input-group-btn">
                        <button class="btn btn-danger" type="submit">
                          <i class="bi bi-search fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> -->
              </tr>
            </table>

            <pagination v-model="page" :records="total_data" @paginate="onPaginate" class="text-right" :options="pagination_option"/>
          </div>
          <div v-else>
            <p class="m-0 text-center">{{ $t('no_data_found') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'

import NoImage from '@/assets/no_image.png'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
    'CompetitionTab': CompetitionTab,
  },
  data() {
    return {
      base: null,
      arr_factor: [false, false, ],
      arr_school: [
      ],
      page: 1,
      total_data: 0,
      pagination_option: {
        texts: {
          count: '',
        },
      },
      type: 'club',
      competition_data: {},
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    type(){
      this.get_data()
    },
  },
  mounted() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    // this.arr_factor = [true,]

    this.get_competition_data()
    this.get_data()
  },
  methods: {
    onPaginate(){
      this.get_data()
    },
    onActionClicked(index){
      var arr_school = JSON.parse(JSON.stringify(this.arr_school))
      arr_school[index].action_selected = !arr_school[index].action_selected
      for (let x in arr_school){
        if(x != index)
          arr_school[x].action_selected = false
      }

      this.arr_school = arr_school
    },
    async get_competition_data(){
      var response = await this.base.request(this.base.url_api + "/event/category-sport?id=" + this.$route.query.id)
      this.$set(this.arr_factor, 0, true)

      if(response != null){
        if(response.status === "success"){
          response.data.start_date_moment = momentTZ.tz(response.data.start_date, this.base.locale_timezone)
          response.data.end_date_moment = momentTZ.tz(response.data.end_date, this.base.locale_timezone)
          response.data.event.start_school_registration_moment = momentTZ.tz(response.data.event.start_school_registration, this.base.locale_timezone)
          response.data.event.end_school_registration_moment = momentTZ.tz(response.data.event.end_school_registration, this.base.locale_timezone)
          response.data.event.start_registration_team_moment = momentTZ.tz(response.data.event.start_registration_team, this.base.locale_timezone)
          response.data.event.end_registration_team_moment = momentTZ.tz(response.data.event.end_registration_team, this.base.locale_timezone)
          response.data.event.start_payment_registration_moment = momentTZ.tz(response.data.event.start_payment_registration, this.base.locale_timezone)
          response.data.event.end_payment_registration_moment = momentTZ.tz(response.data.event.end_payment_registration, this.base.locale_timezone)
          response.data.event.coach_meeting_moment = momentTZ.tz(response.data.event.coach_meeting, this.base.locale_timezone)
          response.data.event.medal_ceremony_moment = momentTZ.tz(response.data.event.medal_ceremony, this.base.locale_timezone)

          this.competition_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_data() {
      var response = await this.base.request(this.base.url_api + "/user?event_category_sport_id=" + this.$route.query.id + "&page=" + this.page + "&type_name=" + this.type)
      this.$set(this.arr_factor, 1, true)

      if (response != null) {
        if (response.status === "success") {
          for (let user of response.data) {
            user.image = user.file_name != null ? this.base.host + "/media/user?file_name=" + user.file_name : NoImage
            user.action_selected = false
          }
          this.total_data = response.recordsTotal
          this.arr_school = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
.competition-detail-venue-court{
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>

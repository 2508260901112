<template>
  <div class="h-100 w-100">
    <PaymentBase/>

    <div class="custom-navbar-padding-left custom-navbar-padding-right w-100 d-flex align-items-center justify-content-center" style="overflow-y: scroll;">
      <div class="card my-5">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6">
              <p class="m-0">{{ $t('payment_method') }}</p>

              <div class="mt-4">
                <div class="card mb-3" v-for="(payment_method, index) in arr_payment_method" :key="index" @click="onPaymentMethodClicked(index)" style="cursor: pointer;">
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <img src="" class="rounded-circle" style="height: 3rem; width: 3rem;"  :alt="'payment method image ' + (index + 1)"/>
                        <p class="m-0 ml-3">{{ payment_method.name }}</p>
                      </div>

                      <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <DetailPayment :arr_team="arr_team"
                :is_many_team="is_many_team"
                :category_team="category_team"
                :arr_button="arr_button"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

import PaymentBase from '@/layout/payment_base.vue'
import DetailPayment from '@/pages/payment/detail_payment.vue'

export default {
  components: {
    'PaymentBase': PaymentBase,
    'DetailPayment': DetailPayment,
  },
  data(){
    return{
      base: null,
      arr_team: [],
      is_many_team: false,
      category_team: {},
      arr_button: [],
      arr_payment_method: [],
      registration_data: {},
    }
  },
  created(){
    this.base = new Base()
    var registration_data = window.localStorage.getItem('registration_data')
    registration_data = JSON.parse(registration_data)
    this.registration_data = registration_data
    this.is_many_team = registration_data.arr_team != null
    if(registration_data.arr_team != null)
      this.arr_team = registration_data.arr_team
    else
      this.category_team = registration_data.category

    this.get_payment_method()
  },
  methods: {
    async get_payment_method(){
      var response = await this.base.request(this.base.url_api + "/payment-method/all?is_active=1")

      if(response != null){
        if(response.status === "success"){
          this.arr_payment_method = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async onPaymentMethodClicked(index){
      var user = window.localStorage.getItem('user')
      user = JSON.parse(user)

      var payment_method = this.arr_payment_method[index]
      this.registration_data.payment_method = payment_method
      this.registration_data.user_id = user.id
      if(this.registration_data.arr_team != null && this.registration_data.arr_team.length > 0)
        this.registration_data.event_category_sport_category_id = this.registration_data.arr_team[0].category_id

      var response = await this.base.request(this.base.url_api + "/event/registration", "post", this.registration_data)
      if(response != null){
        if(response.status === "success"){
          console.log(response)
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
</style>

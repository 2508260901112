<template>
  <div>
    <!-- <div class="navbar navbar-expand-md custom-navbar-padding d-flex justify-content-between align-items-center w-100 py-0" style="top: 0;" :class="customClass" :style="{backgroundColor: '#015057',}"> -->
    <div class="bg-danger" v-if="user != null && user.id != null && user.type.name == 'coordinator'">
      <div class="container py-2">
        <small class="text-white px-2 m-0 text-uppercase font-weight-bold">coordinator signed in</small>
      </div>
    </div>
    <div class="navbar navbar-expand-md" style="top: 0;" :class="customClass" :style="{ backgroundColor: '#015057', }">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img src="@/assets/logo_new.png" style="height: 4rem;" alt="logo"/>
        </a>

        <button class="navbar-toggler border-0" type="button" @click="onNavbarToggle">
          <font-awesome-icon icon="fa-solid fa-bars" class="ml-3 text-white" size="lg" />
        </button>

        <div class="d-none d-md-block" id="navbarToggle">
          <ul class="navbar-nav ml-auto mt-5 mt-md-0">
            <li class="d-flex align-items-center nav-item active">
              <a class="nav-link" :class="{ 'active': currentRoute === '/' }" href="/">{{ $t("home") }}</a>
            </li>
            <li class="d-flex align-items-center nav-item">
              <a class="nav-link" :class="{ 'active': currentRoute === '/about-us' }" href="/about-us">{{ $t("about_us")
              }}</a>
            </li>
            <li class="d-flex align-items-center nav-item">
              <a class="nav-link" :class="{ 'active': currentRoute === '/member' }" href="/member">{{ $t("member") }}</a>
            </li>
            <li class="d-flex align-items-center nav-item">
              <a class="nav-link" :class="{ 'active': currentRoute === '/sponsorship' }" href="/sponsorship">{{
                $t("sponsorship") }}</a>
            </li>
						<li class="d-flex align-items-center nav-item">
							<a class="nav-link" :class="{ 'active': currentRoute === '/champion' }" href="/champion">{{
								$t("champion") }}</a>
						</li>
            <li class="d-flex align-items-center nav-item" v-if="user != null && user.id != null && user.type.name == 'coordinator'">
              <a href="#" class="nav-link" @click="onChangeProfileClicked">{{ $t("change_profile") }}</a>
            </li>
            <li class="d-flex align-items-center nav-item" v-if="user != null && user.id != null && user.type.name == 'coordinator'">
              <a href="#" class="nav-link" @click="onChangePasswordClicked">{{ $t("change_password") }}</a>
            </li>
            <li class="d-flex align-items-center nav-item">
              <div class="nav-link" style="padding-right: 0 !important;">
                <!-- <a class="btn btn-warning text-white px-3 py-0 rounded-pill" href="/auth/login"> -->
                <!-- <a class="btn btn-warning text-white px-3 py-0 rounded-pill" href="#" data-toggle="modal">
                  <div class="d-flex align-items-center justify-content-center" style="height: 2.5rem;">
                    <p class="mb-0 text-dark" style="line-height: 100%; font-size: 1rem; margin-top: .2rem;">{{ $t("login")
                    }}</p>
                  </div>
                </a> -->
                <button type="button" class="btn btn-warning text-dark rounded-pill px-4" @click="onLoginClicked" style="height: 2.5rem;">
                  {{ token == null ? 'Login' : (user != null && user.id != null && user.type.name == 'coordinator' ? 'Logout' : 'User Admin') }}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>


      <!-- <div class="d-flex align-items-center navbar-header">
        <a href="/">{{ $t("home") }}</a>
        <a class="ml-4" href="/schedule">{{ $t("auction_schedule") }}</a>
        <a class="ml-4" href="/definiton">{{ $t("auction_info") }}</a>
        <a class="ml-4" href="/">{{ $t("auction_location") }}</a>
        <a class="ml-4" href="/">{{ $t("about_us") }}</a>
        <button class="btn btn-primary ml-4" href="/">{{ $t("register") }}</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base'

export default {
  props: ['scrollY',],
  data() {
    return {
      base: null,
      currentRoute: '/',
      customClass: 'navbar-home-gray1',
      isNavbarToggle: false,
      arr_custom_navbar: [],
      token: '',
      user: {},
    }
  },
  watch: {
    isNavbarToggle(val) {
      console.log(val)
      this.$emit('onNavbarToggle', val)
    },
    scrollY(val) {
      if (this.currentRoute === '/') {
        if (val < this.base.responsive_scroll_threshold(500))
          this.customClass = 'navbar-home-gray1'
        else if (val >= this.base.responsive_scroll_threshold(500) && val < this.base.responsive_scroll_threshold(1200))
          this.customClass = 'navbar-home-white'
        else if (val >= this.base.responsive_scroll_threshold(1200) && val < this.base.responsive_scroll_threshold(1700))
          this.customClass = 'navbar-home-gray4'
        else
          this.customClass = 'navbar-home-white'
      }
      else if (this.currentRoute === '/how-to') {
        if (val < this.base.responsive_scroll_threshold(600))
          this.customClass = 'navbar-home-white'
        else if (val >= this.base.responsive_scroll_threshold(600) && val < this.base.responsive_scroll_threshold(2000))
          this.customClass = 'navbar-home-gray4'
        else
          this.customClass = 'navbar-home-white'
      }
      else {
        this.customClass = 'navbar-home-white'
      }
    },
    arr_custom_navbar(val) {
      for (let x in val) {
        if (val[x].is_show)
          window.$('#custom_level1_menu' + x).addClass('show')
        else
          window.$('#custom_level1_menu' + x).removeClass('show')
        for (let y in val[x].arr) {
          if (val[x].arr[y].is_show)
            window.$('#custom_level2_menu' + y).addClass('show')
          else {
            console.log(window.$('#custom_level2_menu' + y))
            window.$('#custom_level2_menu' + y).removeClass('show')
          }
        }
      }
    },
  },
  async created() {
    this.base = new Base()
    this.currentRoute = this.$router.currentRoute.path
    this.customClass = this.currentRoute === '/' ? 'navbar-home-normal-gray1' : 'navbar-home-normal-white'

    // this.get_navbar()
    this.token = await window.localStorage.getItem('token')
    var user = await window.localStorage.getItem('user')
    this.user = JSON.parse(user)
  },
  methods: {
    onNavbarToggle() {
      this.isNavbarToggle = !this.isNavbarToggle
    },
    async onLoginClicked(){
      if(this.token == null)
        window.$('#login-modal').modal('show')
      else if(this.user.id != null && this.user.type.name == 'coordinator'){
        await window.localStorage.removeItem('token')
        await window.localStorage.removeItem('token_expired')
        await window.localStorage.removeItem('user')

        window.location.reload()
      }
      else
        window.location.href = this.base.admin_host
    },
    async onChangeProfileClicked(){
      window.$('#change-profile-modal').modal('show')
    },
    async onChangePasswordClicked(){
      window.$('#change-password-modal').modal('show')
    },
    logout() {
      localStorage.removeItem('token')
      location.href = "/auth/login"
    },
    onLevel1Clicked(index1) {
      // window.$('#custom_level1' + index1).dropdown('toggle')

      var arr = JSON.parse(JSON.stringify(this.arr_custom_navbar))
      arr[index1].is_show = !arr[index1].is_show
      this.arr_custom_navbar = arr
    },
    onLevel2Clicked(index1, index2) {
      var arr = JSON.parse(JSON.stringify(this.arr_custom_navbar))
      arr[index1].arr[index2].is_show = !arr[index1].arr[index2].is_show
      this.arr_custom_navbar = arr
    },
    onRedirectLevel1(index1) {
      window.localStorage.setItem('custom_navbar_level1', JSON.stringify(this.arr_custom_navbar[index1]))
      window.localStorage.setItem('custom_navbar_level2', JSON.stringify({}))
      window.localStorage.setItem('custom_navbar_level3', JSON.stringify({}))
    },
    onRedirectLevel2(index1, index2) {
      window.localStorage.setItem('custom_navbar_level1', JSON.stringify(this.arr_custom_navbar[index1]))
      window.localStorage.setItem('custom_navbar_level2', JSON.stringify(this.arr_custom_navbar[index1].arr[index2]))
      window.localStorage.setItem('custom_navbar_level3', JSON.stringify({}))
    },
    onRedirectLevel3(index1, index2, index3) {
      window.localStorage.setItem('custom_navbar_level1', JSON.stringify(this.arr_custom_navbar[index1]))
      window.localStorage.setItem('custom_navbar_level2', JSON.stringify(this.arr_custom_navbar[index1].arr[index2]))
      window.localStorage.setItem('custom_navbar_level3', JSON.stringify(this.arr_custom_navbar[index1].arr[index2].arr[index3]))
    },
    async get_navbar() {
      var response = await this.base.request(this.base.url_api + "/navbar/level1/all")

      if (response != null) {
        if (response.status === "success") {
          for (let level1 of response.data) {
            level1.id_frontend = level1.name.replace(' ', '_').toLowerCase()
            level1.is_show = false
            level1.arr = level1.navbar_level2
            for (let level2 of level1.arr) {
              level2.id_frontend = level2.name.replace(' ', '_').toLowerCase()
              level2.is_show = false
              level2.arr = level2.navbar_level3
              for (let level3 of level2.arr) {
                level3.id_frontend = level3.name.replace(' ', '_').toLowerCase()
                level3.is_show = false
              }
            }
          }
          this.arr_custom_navbar = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 960px) {
  .nav-link {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }
}

@media only screen and (min-width: 960px) {
  .nav-master-dropdown:hover>.nav-dropdown>.dropdown-menu {
    display: block;
  }

  .nav-link {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.navbar-header * {
  font-family: poppins-bold;
}

.navbar-header a,
.list-group a {
  text-decoration: none;
  color: $black;
}

.navbar-header button {
  border-radius: .5rem;
}

.navbar-home-normal-gray1 {
  background-color: $gray1;
}

.navbar-home-normal-white {
  background-color: $white;
}

.navbar-home-gray1 {
  transition: all 1s;
  background-color: $navbar-gray1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.navbar-home-white {
  transition: all 1s;
  background-color: $navbar-white;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.navbar-home-gray4 {
  transition: all 1s;
  background-color: $navbar-gray4;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.nav-dropdown .dropdown-toggle::after {
  display: none;
}

.nav-dropdown .dropdown-item,
.nav-arrow {
  color: $black;
  font-family: poppins-medium;
  font-size: .8rem;
}

.nav-link {
  color: $black;
  font-family: poppins-medium;
}

.nav-link {
  color: $warning !important;
  font-family: poppins-bold;
}

.nav-dropdown .dropdown-item.active {
  background-color: $primary !important;
  font-size: .8rem;
  color: $white !important;
  font-family: poppins-bold;
}</style>

<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail :title="$t('about_us')"/>
    </div>

    <div class="container py-5">
      <p class="m-0 text-primary text-center supporting-title">Who  We Are</p>
      <p class="m-0 font mt-3 p-spacing gray-777" v-html="who_we_are"></p>
    </div>

    <div class="py-5 bg-primary">
      <div class="container">
        <p class="m-0 text-warning text-center supporting-title">The Logo and Mascot</p>
        <div class="container">
          <div class="row">
            <div class="col-12 col-xl-auto text-center h-auto d-flex flex-column flex-sm-row flex-xl-column justify-content-center align-items-center">
              <img src="@/assets/logo_new.png" style="height: 20rem; width: fit-content" alt="logo">
              <img src="@/assets/mascott.png" style="height: 20rem; width: fit-content" alt="mascott logo">
            </div>
            <div class="col-12 col-xl h-auto d-flex align-items-center">
              <p class="m-0 text-white mt-3 p-spacing" v-html="logo_mascott"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container position-relative">
      <div class="row">
        <p class="d-block d-sm-none mt-5 text-primary text-center w-100 supporting-title ">The Medal</p>

        <div class="col-12 col-md-6">
          <div class="d-flex justify-content-center">
            <div class="text-right">
              <img src="@/assets/gold_front.png" width="100%" alt="gold logo">
            </div>
            <div class="text-right">
              <img src="@/assets/silver_front.png" width="100%" alt="silver logo">
            </div>
          </div>
          <p class="mb-5 mb-md-0 mt-0 mt-md-3 text-primary text-center about-us-description">Front</p>
        </div>

        <div class="col-12 col-md-6">
          <div class="d-flex justify-content-center">
            <div>
              <img src="@/assets/silver_back.png" width="100%" alt="silver back logo">
            </div>
            <div>
              <img src="@/assets/gold_back.png" width="100%" alt="gold back logo">
            </div>
          </div>
          <p class="mb-3 mb-md-0 mt-3 text-primary text-center about-us-description">Back</p>
        </div>

        <p class="d-none d-sm-block m-0 text-primary text-center supporting-title position-absolute w-100" style="top: 3rem;">The Medal</p>
        <div class="col-12">
          <p class="mt-3 pb-5 about-us-medal-detail p-spacing" v-html="medal"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

import NavbarDetail from '@/layout/navbar_detail.vue'

// import Wallet from '@/assets/wallet.png'
// import OurTeam1 from '@/assets/our_team1.png'
// import OurTeam2 from '@/assets/our_team2.png'
// import OurTeam3 from '@/assets/our_team3.png'
// import SectionImage from '@/assets/about_us_top.png'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
  },
  data(){
    return{
      base: null,
      scrollY: 0,
      arr_factor: [false, ],
      flag: {
        aboutUsTitle1Flag: false,
        aboutUsImage1Flag: false,
        aboutUsContent1Flag: false,
        aboutUsTitle2Flag: false,
        aboutUsImage2Flag: false,
        aboutUsContent2Flag: false,
        aboutUsTitle3Flag: false,
        aboutUsImage3Flag: false,
        aboutUsContent3Flag: false,
      },
      who_we_are: `<span class="m-0 text-primary about-us-brand">STUDENT OPEN</span> is a student development program through sports activities in the form of league matches in the fields of football, basketball, tennis, badminton and taekwondo organized by PT Sportina Globalution Societas. The League match system was chosen because the concept of the match matches the objectives of gradual student and sports development and requires increasing experience through many matches and in accordance with the principles of sportsmanship. The competition categories are based on age age student (elementary, junior school, senior school and University) for all sports, so that participants are students who can represent schools from elementary, middle school, high school and university levels. Schools and universities can also use it to promote their schools/universities and to introduce their products to the general public through students competing and through companies introducing their products.`,
      logo_mascott: `The symbol of Student Open is called a growing sunflower, which means that with student open activities it is hoped that those who participate and are involved in activities can develop better over time. The gold color is intended for everyone to get the expected victory and according to their respective developments. A circle around it indicates that achieving success in every game requires a great deal of determination. As part of the synergistic cycle, you must practice, develop, and overcome challenges in order to reach your goals. The Student Open mascot is called MACINA which comes from a combination of the words Macan and Indonesia. The macina design contains various elements that represent the sporting spirit and diversity of Indonesia, such as red clothes with white patterns which symbolize the Indonesian flag, the patterns on the uniform form a well-combined batik pattern and a cleaver weapon in the form of a sliuet. Macina has a cheerful character and likes various sports which is shown in her facial expressions and has a passion for sports which is a competition category in STUDENT OPEN.`,
      medal: `Our medal is a 3D concept with a diameter of 8cm and a thickness of 3mm. On the front side, sports are depicted individually, while on the reverse side, they are depicted in teams. Our medals are sources of pride and representations of the participants' accomplishments in activities, so we make sure to represent this in the quality of what we make.`,
    }
  },
  watch: {
    arr_factor(val){
      this.$emit('onChangeArrFactor', val)
      this.manage_start_animation()
    },
    scrollY(){
      this.manage_start_animation()
    },
  },
  created(){
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1
    // this.arr_factor = [true, true, true, true, ]

    this.get_about_us_info()
  },
  methods: {
    onImageLoad(index){
      var team = this.arr_team[index]
      team.is_image_loaded = true
      this.$set(this.arr_team, index, team)
    },
    manage_start_animation(){
      var context = this
      this.flag.aboutUsTitle1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsTitle1Flag, this.arr_factor, 0)
      this.flag.aboutUsImage1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsImage1Flag, this.arr_factor, 0)
      this.flag.aboutUsContent1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsContent1Flag, this.arr_factor, 0)

      setTimeout(() => {
        if(context.flag.aboutUsContent1Flag){
          var margin = window.$('#about-us-content').height()
          context.flag.aboutUsTitle2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle2Flag, context.arr_factor, 0, margin - margin)
          context.flag.aboutUsContent2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent2Flag, context.arr_factor, 0, margin - 300)

          if(context.flag.aboutUsContent2Flag){
            context.flag.aboutUsImage2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage2Flag, context.arr_factor, 0, margin + window.$('#trust-content').innerHeight() - 300)

            if(context.flag.aboutUsImage2Flag){
              margin += window.$('#trust-container').innerHeight() - 950
              context.flag.aboutUsTitle3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsImage3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsContent3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent3Flag, context.arr_factor, 800, margin)
            }
          }
        }
      }, 200)

    },
    handleScroll(){
      this.scrollY = window.scrollY
    },
    async get_about_us_info(){
      var response = await this.base.request(this.base.url_api + `/info?arr_type=["about_us_who_we_are","about_us_logo_mascott","about_us_medal"]`)
      this.$set(this.arr_factor, 0, true)

      if(response != null){
        if(response.status === "success"){
          for(let info of response.data){
            if(info.type == "about_us_who_we_are")
              this.who_we_are = info.content
            else if(info.type == "about_us_logo_mascott")
              this.logo_mascott = info.content
            else if(info.type == "about_us_medal")
              this.medal = info.content
          }
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
.custom-title{
  color: $primary;
  font-family: poppins-bold;
}
.about-us-brand{
  font-family: montserrat-bold;
  font-size: 1rem;
}
.about-us-medal-detail{
  color: $gray20;
}
.about-us-description{
  font-family: montserrat-bold;
  font-size: 1rem;
}
.trust-card{
  background-color: $gray9;
  border: none;
}
.title-section{
  font-size: 2rem;
  font-family: poppins-medium;
}
.content-section{
  color: $gray6;
}
.team-role{
  color: $black1;
}
.team-name{
  color: $black1;
  font-family: poppins-bold;
}
.about-us-title1-enter-active, .about-us-title1-leave-active,
.about-us-title2-enter-active, .about-us-title2-leave-active,
.about-us-title3-enter-active, .about-us-title3-leave-active{
  transition: all 2s;
}
.about-us-title1-leave-to, .about-us-title1-enter,
.about-us-title2-leave-to, .about-us-title2-enter,
.about-us-title3-leave-to, .about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}
.about-us-content1-enter-active, .about-us-content1-leave-active,
.about-us-content2-enter-active, .about-us-content2-leave-active,
.about-us-content3-enter-active, .about-us-content3-leave-active{
  transition: all 2s;
}
.about-us-content1-leave-to, .about-us-content1-enter,
.about-us-content2-leave-to, .about-us-content2-enter,
.about-us-content3-leave-to, .about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}
.about-us-image1-enter-active, .about-us-image1-leave-active,
.about-us-image2-enter-active, .about-us-image2-leave-active,
.about-us-image3-enter-active, .about-us-image3-leave-active{
  transition: all 2s;
}
.about-us-image1-leave-to, .about-us-image1-enter,
.about-us-image2-leave-to, .about-us-image2-enter,
.about-us-image3-leave-to, .about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}
#about-us-content img{
  width: 100%;
  border-radius: 1rem;
}
.trust-list > p:nth-child(1){
  min-height: 100px;
  text-align: center;
}
.trust-list > p:nth-child(2){
  text-align: justify;
  text-align-last: center;
}
.p-spacing{
  text-align: justify;
  word-spacing: 0.1rem;
  line-height: 200%;
}
.gray-777{
  color: $gray20;
}
</style>

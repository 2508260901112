<template>
  <div>
		<div v-for="(question, index) in arr_question" :key="index" class="my-5">

			<div class="d-flex align-items-center">
				<div class="card">
					<div class="card-body p-3">
						<p class="m-0" style="line-height: 100%;">{{ offset_num + index }}</p>
					</div>
				</div>

				<p class="m-0 ml-3">Question #{{ offset_num + index }}</p>
			</div>

			<div class="d-flex align-items-center mt-3">
				<div class="quiz-question-item-label">
					<p class="m-0">Question</p>
					<p class="m-0">(Max. 200 Character)</p>
				</div>
				<div class="d-flex quiz-question-item-input">
					<textarea class="form-control" v-model="question.title" style="margin-left: 2rem;"></textarea>
				</div>
			</div>

			<div class="d-flex align-items-start mt-4">
				<div class="quiz-question-item-label">
					<p class="m-0">Option</p>
					<p class="m-0">(Max. 100 Character)</p>
				</div>
				<div class="quiz-question-item-input">
					<div class="d-flex align-items-start" :class="{'mt-3': index1 > 0,}" v-for="(option, index1) in question.option" :key="index1">
						<div class="question-option-icon mt-2" style="cursor: pointer" @click="onCheckOptionClicked(index, index1)">
							<font-awesome-icon :icon="['far', 'circle']" class="text-primary" v-show="!option.is_correct"/>
							<font-awesome-icon :icon="['fas', 'circle-check']" class="text-primary" v-show="option.is_correct"/>
						</div>
						<textarea class="form-control mx-3" v-model="option.option"></textarea>
						<CustomImagePicker
							style="margin-top: .2rem;"
							:with_info="false"
							:with_remove="true"
							:image_data1="option.image_data"
							@onResponse="(response) => onOptionImageResponse(response, index, index1)"
							@onRemoveClicked="onOptionRemoveImageClicked(index, index1)"/>
						<div class="ml-3" style="width: 5rem; height: 2rem;">
							<font-awesome-icon :icon="['fas', 'circle-xmark']" class="text-primary" style="cursor: pointer; margin-top: .7rem;" @click="onRemoveOptionClicked(index, index1)"/>
						</div>
					</div>

					<div class="d-flex align-items-center mt-3" style="cursor: pointer" @click="onAddOptionClicked(index)">
						<font-awesome-icon :icon="['fas', 'square-plus']" class="text-primary"/>
						<p class="m-0 ml-3">Add Option</p>
					</div>
				</div>
			</div>

			<div class="d-flex align-items-center mt-3">
				<div class="quiz-question-item-label">
					<p class="m-0" style="">Image</p>
				</div>
				<div class="quiz-question-item-input">
					<CustomImagePicker
						style="margin-left: 2rem;"
						:with_info="false"
						:image_data1="question.image_data"
						@onResponse="(response) => onImageResponse(response, index)"/>
				</div>
			</div>

		</div>
  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";
import CustomImagePicker from "@/layout/custom_imagepicker";

export default {
  props: ["quiz_data", 'selected_tab', 'is_submit', 'page', 'num_data', 'num_question_per_page', 'is_change_tab', 'index', 'is_change_tab1', 'tab_type', ],
  components: {
		CustomImagePicker: CustomImagePicker,
	},
  data() {
    return {
      base: null,
			arr_question: [],
			offset_num: 0,
    };
  },
	watch: {
		arr_question: {
			handler(newValue, oldValue) {
				for(let question of newValue){
					question.title = question.title.substring(0, 200)
					for(let option of question.option)
						option.option = option.option.substring(0, 100)
				}
			},
			deep: true,
		},
		num_question_per_page(val){
			this.manage_question()
		},
		page(val){
			this.manage_question()
			this.offset_num = ((val - 1) * this.num_data) + 1
		},
		num_data(val){
			this.manage_question()
		},
		is_change_tab(val){
			if(val)
				this.validate()
		},
		is_change_tab1(val){
			if(val)
				this.validate()
		},
		selected_tab(val){
			if(val == this.index)
				this.validate()
		},
	},
  async mounted() {
    this.base = new Base();

		this.offset_num = ((this.page - 1) * this.num_data) + 1
		this.manage_question()
  },
  methods: {
		validate(){
			var message = ''
			var index = -1
			if(this.tab_type == "next"){
				for(let x in this.arr_question){
					if(this.arr_question[x].option.length == 0)
						message = 'Option on Question #' + (parseInt(x) + this.offset_num) + ' is Empty'
					else if(this.arr_question[x].option.length == 1)
						message = 'Option on Question #' + (parseInt(x) + this.offset_num) + ' must more than 1 option'
					else{
						var counter = 0
						for(let y in this.arr_question[x].option){
							if((this.arr_question[x].option[y].option == '' || this.arr_question[x].option[y].option == null) && this.arr_question[x].option[y].image_data == null)
								message = 'Option #' + (parseInt(y) + 1) + ' on Question #' + (parseInt(x) + this.offset_num) + ' is Empty'
							else if(!this.arr_question[x].option[y].is_correct)
								counter++

							for(let z in this.arr_question[x].option){
								if(y != z && this.arr_question[x].option[y].option == this.arr_question[x].option[z].option)
									message = 'Option #' + (parseInt(y) + 1) + ' on Question #' + (parseInt(x) + this.offset_num) + ' is Same with Option #' + (parseInt(z) + 1)
							}
						}
						if(message == '' && counter == this.arr_question[x].option.length)
							message = 'Correct Option on Question #' + (parseInt(x) + this.offset_num) + ' not Found'
					}

					if(message != ''){
						index = x
						break
					}
					else if(this.arr_question[x].title == '' || this.arr_question[x].title == null){
						message = 'Question #' + (parseInt(x) + this.offset_num) + ' is Empty'
						index = x
						break
					}
				}
			}

			this.$emit('onValidate', index, message, {
				arr_question: this.arr_question,
			})
		},
		manage_question(){
			var arr_question = this.quiz_data.question
			var arr_question1 = []
			var start_pagination = (this.page - 1) * this.num_data


			for(let x in arr_question){
				if(x >= start_pagination && x < start_pagination + this.num_question_per_page)
					arr_question1.push(arr_question[x])
			}
			if(arr_question1.length < this.num_question_per_page){
				for(let x = arr_question1.length; x < this.num_question_per_page; x++)
					arr_question1.push({
						title: '',
						option: [
							{
								option: '',
								is_correct: false,
								image_data: {},
							},
						],
						image_data: {},
					})
			}
			this.arr_question = arr_question1
		},
		onCheckOptionClicked(index, index1){
			var arr_question = JSON.parse(JSON.stringify(this.arr_question))
			for(let option of arr_question[index].option)
				option.is_correct = false
			arr_question[index].option[index1].is_correct = !arr_question[index].option[index1].is_correct
			this.arr_question = arr_question
		},
		onRemoveOptionClicked(index, index1){
			var arr_question = JSON.parse(JSON.stringify(this.arr_question))
			arr_question[index].option.splice(index1, 1)
			this.arr_question = arr_question
		},
		onAddOptionClicked(index){
			var arr_question = JSON.parse(JSON.stringify(this.arr_question))
			arr_question[index].option.push({
				option: '',
				is_correct: false,
				image_data: {},
			})
			this.arr_question = arr_question
		},
		onOptionRemoveImageClicked(index, index1){
			var arr_question = JSON.parse(JSON.stringify(this.arr_question))
			arr_question[index].option[index1].image_data = {}
			this.arr_question = arr_question
		},
		onOptionImageResponse(response, index, index1){
			var resp = response
			resp.data_image = null

			var arr_question = JSON.parse(JSON.stringify(this.arr_question))
			arr_question[index].option[index1].image_data = resp
			this.arr_question = arr_question
		},
		onImageResponse(response, index){
			var resp = response
			resp.data_image = null

			var arr_question = JSON.parse(JSON.stringify(this.arr_question))
			arr_question[index].image_data = resp
			this.arr_question = arr_question
		},
		async get_data() {
			window.$('#please_wait_modal').modal('show')

			var response = await this.base.request(
				this.base.url_api + "/quiz/question?match_id=" + this.match_id +
				"&page=" + this.page +
				"&num_data=" + this.num_data
			);

			setTimeout(() => {
				window.$('#please_wait_modal').modal('hide')
			}, 500)


			if (response != null) {
				if (response.status == "success") {
					this.arr_question = response.data
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
	},
};
</script>

<style lang="scss">

	.question-option-icon{
		width: 1rem;
		height: 1rem;
	}

</style>

<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail
        :title="competition_data.category_sport != null && competition_data.category_sport.name != null ? competition_data.category_sport.name : ''"
      />
    </div>
    <CompetitionTab :competition_data="competition_data" />

    <div class="my-5 pb-5 container">
      <div class="py-3" v-if="front_type == 'list'">
        <p class="m-0 competition-detail-title">{{ $t("quiz") }}</p>

        <div class="py-3">
					<div class="d-flex">

						<div class="form-group">
							<select class="form-control" style="font-size: 0.75rem; height: 2.5rem; width: auto; background-position: right 0.75rem center;" v-model="type">
								<option value="">All</option>
								<option value="club">Sport Club</option>
								<option value="school">Academy</option>
							</select>
						</div>

						<div class="form-group ml-3">
							<select class="form-control" style="font-size: 0.75rem; height: 2.5rem; width: auto; background-position: right 0.75rem center" v-model="category">
								<option value="">All</option>
								<option :value="event_category_sport_category.id" v-for="(event_category_sport_category, index) in arr_event_category_sport_category" :key="index">
									{{ event_category_sport_category.name }}
								</option>
							</select>
						</div>

					</div>
        </div>

				<div>

					<div v-if="arr_match.length > 0">
						<div v-for="(match, index) in arr_match" :key="index">
							<p class="m-0 font-weight-bold">{{ match.event_category_sport_category.name }}</p>

							<div class="d-flex px-3 px-lg-4 mt-3">
								<div class="quiz-label pr-1 quiz-sub-category-cont">Sub Category</div>
								<div class="quiz-label px-1 d-none d-lg-block quiz-duration-cont">Duration</div>
								<div class="quiz-label px-1 d-none d-lg-block quiz-quest-cont">Quest.</div>
								<div class="quiz-label px-1 d-none d-lg-block quiz-quest-cont">Last Update</div>
								<div class="quiz-label px-1 quiz-status-cont">Status</div>
								<div class="quiz-label pl-1 quiz-status-cont">Action</div>
							</div>

							<div class="mt-3">
								<div v-for="(match1, index1) in match.arr_match" :key="index1" class="py-4 px-3 p-lg-4 d-flex align-items-center quiz-match-box">
									<p class="m-0 text-break pr-1 quiz-sub-category-cont">{{ match.event_category_sport_category.name }}{{ match1.tournament != null ? ' | Tournament ' + match1.tournament.level_format + ' ' + match1.tournament.num : '' }}</p>

									<p class="m-0 px-1 quiz-label d-none d-lg-block quiz-duration-cont">
										{{ match.event_category_sport_category.quiz_timer_moment.hours() < 10 ? '0' + match.event_category_sport_category.quiz_timer_moment.hours() : match.event_category_sport_category.quiz_timer_moment.hours() }}
										:
										{{ match.event_category_sport_category.quiz_timer_moment.minutes() < 10 ? '0' + match.event_category_sport_category.quiz_timer_moment.minutes() : match.event_category_sport_category.quiz_timer_moment.minutes() }}
									</p>
									<p class="m-0 px-1 quiz-label d-none d-lg-block quiz-quest-cont">{{ match1.quiz.length > 0 ? match1.quiz[0].question.length : '0' }}</p>
									<p class="m-0 text-break px-1 quiz-label d-none d-lg-block quiz-quest-cont">{{ match1.quiz.length > 0 ? match1.quiz[0].updated_at_moment.format('DD/MM/YYYY') : 'N/A' }}</p>
									<div class="m-0 px-1 text-center quiz-status-cont" v-html="match1.quiz_status_format"></div>
									<div class="m-0 pl-1 quiz-status-cont" v-show="match1.quiz_allow_edit">
										<button class="btn btn-primary w-100" @click="onViewClicked(match.event_category_sport_category, match1)">View</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<h4 class="text-center">No Quiz Found</h4>
					</div>

				</div>

      </div>

			<QuizEdit v-else-if="front_type == 'edit'"
				:name="name"
				:match_id="match_id"
				:competition_data="competition_data"
				@onBackClicked="onBackClicked"/>
    </div>
  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";

import NavbarDetail from "@/layout/navbar_detail.vue";
import CompetitionTab from "@/pages/competition/tab.vue";
import QuizEdit from "@/pages/competition/quiz_edit.vue";

import NoImage from "@/assets/no_image.png";

export default {
  components: {
    NavbarDetail: NavbarDetail,
    CompetitionTab: CompetitionTab,
		QuizEdit: QuizEdit,
  },
  data() {
    return {
      base: null,
      arr_factor: [false, false],
			front_type: 'list',
			name: '',
			match_id: '',
			type: '',
			category: '',
			arr_match: [],
      competition_data: {},
			arr_event_category_sport_category: [],
    };
  },
  watch: {
    arr_factor(val) {
      this.$emit("onChangeArrFactor", val);
    },
    type() {
			window.$('#please_wait_modal').modal('show')
      this.get_data();
    },
		category(){
			window.$('#please_wait_modal').modal('show')
			this.get_data();
		},
		group_data(val){
			window.$('#editTournament').modal('show')
		},
  },
  async created() {
    this.base = new Base();
    // window.addEventListener('scroll', this.handleScroll)
    this.arr_factor = [true];
		var token = await window.localStorage.getItem('token')

    var user = await window.localStorage.getItem('user')
    this.user = JSON.parse(user)

		if(token != null)
			this.get_user_data()
		this.get_event_category_sport_category_data()
    this.get_competition_data();
		this.get_data();
  },
  methods: {
		async get_user_data() {
			var response = await this.base.request(this.base.url_api + "/auth/profile?event_category_sport_id=" + this.$route.query.id);

			if (response != null) {
				if (response.status === "success") {
					this.user = response.data;
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
		async get_event_category_sport_category_data() {
			var response = await this.base.request(
				this.base.url_api + "/event/category-sport/category/all?event_category_sport_id=" + this.$route.query.id
			);
			this.$set(this.arr_factor, 1, true);

			if (response != null) {
				if (response.status === "success") {
					this.arr_event_category_sport_category = response.data;
				}
				else
					this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
    onForceReload() {
      this.get_data();
    },
    async get_competition_data() {
      var response = await this.base.request(
        this.base.url_api + "/event/category-sport?id=" + this.$route.query.id
      );
      this.$set(this.arr_factor, 0, true);

      if (response != null) {
        if (response.status === "success") {
          response.data.start_date_moment = momentTZ.tz(
            response.data.start_date,
            this.base.locale_timezone
          );
          response.data.end_date_moment = momentTZ.tz(
            response.data.end_date,
            this.base.locale_timezone
          );
          response.data.event.start_school_registration_moment = momentTZ.tz(
            response.data.event.start_school_registration,
            this.base.locale_timezone
          );
          response.data.event.end_school_registration_moment = momentTZ.tz(
            response.data.event.end_school_registration,
            this.base.locale_timezone
          );
          response.data.event.start_registration_team_moment = momentTZ.tz(
            response.data.event.start_registration_team,
            this.base.locale_timezone
          );
          response.data.event.end_registration_team_moment = momentTZ.tz(
            response.data.event.end_registration_team,
            this.base.locale_timezone
          );
          response.data.event.start_payment_registration_moment = momentTZ.tz(
            response.data.event.start_payment_registration,
            this.base.locale_timezone
          );
          response.data.event.end_payment_registration_moment = momentTZ.tz(
            response.data.event.end_payment_registration,
            this.base.locale_timezone
          );
          response.data.event.coach_meeting_moment = momentTZ.tz(
            response.data.event.coach_meeting,
            this.base.locale_timezone
          );
          response.data.event.medal_ceremony_moment = momentTZ.tz(
            response.data.event.medal_ceremony,
            this.base.locale_timezone
          );

          this.competition_data = response.data;
        } else this.base.show_error(response.message);
      } else console.log(this.$t("server_error"));
    },
		onBackClicked(){
			this.front_type = 'list'
			this.get_data()
		},
		onViewClicked(event_category_sport_category, match_data){
			this.match_id = match_data.id
			this.name = event_category_sport_category.name + ' ' + (match_data.tournament != null ? ' | Tournament ' + match_data.tournament.level_format : '')
			this.front_type = 'edit'
		},
    async get_data() {
			var counter = 0
			for(let factor of this.arr_factor){
				if(!factor)
					break
				counter++
			}

			if(counter == this.arr_factor.length)
				window.$('#please_wait_modal').modal('show')

			var token = await window.localStorage.getItem('token')
      this.arr_group = []
      var response = await this.base.request(
        this.base.url_api +
          "/match/academic" + (token != null ? '/auth' : '') + "?category_type=academic" +
					"&api_type=has_quiz" +
					"&type=" + this.type +
					"&event_category_sport_category_id=" + this.category +
          "&event_category_sport_id=" + this.$route.query.id
      );
      this.$set(this.arr_factor, 1, true);
			window.$('#please_wait_modal').modal('hide')

      if (response != null) {
        if (response.status == "success") {
					for(var data of response.data){
						for(var match of data.arr_match){
							if(match.quiz.length > 0)
								match.quiz[0].updated_at_moment = momentTZ.tz(match.quiz[0].updated_at_moment, this.base.locale_timezone)
						}
						data.event_category_sport_category.quiz_timer_moment = momentTZ.duration(data.event_category_sport_category.quiz_timer, 's')
					}
					this.arr_match = response.data
        } else this.base.show_error(response.message);
      } else console.log(this.$t("server_error"));
    },
  },
};
</script>

<style lang="scss">
.quiz-match-box{
	background-color: $gray22;
	border-radius: 8px;
}
@media only screen and (max-width: 1517px) {
	.quiz-label{
		color: $primary;
		font-weight: 700;
		font-size: 1rem;
	}
	.quiz-sub-category-cont{
		width: 40%;
	}
	.quiz-status-cont{
		width: 30%;
		text-align: center;
	}
}
@media only screen and (min-width: 1517px) {
	.quiz-label{
		color: $primary;
		font-weight: 700;
	}
	.quiz-sub-category-cont{
		width: 45%;
	}
	.quiz-duration-cont{
		width: 15%;
		text-align: center;
	}
	.quiz-quest-cont{
		width: 10%;
		text-align: center;
	}
	.quiz-status-cont{
		width: 10%;
		text-align: center;
	}
}
</style>

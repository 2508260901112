<template>
  <div class="">
    <div class="row">

      <div class="col-6">
        <div v-for="(player, index) in arr_player1" :key="index">
          <div
            class="d-flex flex-column flex-md-row flex-fill align-items-start align-items-md-center mt-3"
            style="gap: 0.5rem"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: 4rem; height: 4rem"
            >
              <img
								:alt="'player image ' + (index + 1)"
                :src="player.url_image"
                class="mw-100 mh-100"
              />
            </div>
            <div
              class="d-flex align-items-baseline flex-fill"
              style="gap: 0.5rem"
            >
              <p class="m-0 text-center" style="min-width: 2rem">
                {{ player.back_number }}
              </p>
              <div class="d-flex flex-column align-items-start">
                <p class="m-0 font-weight-bold text-primary">
                  {{ player.name }}
                </p>
                <small class="m-0">
                  {{ player.player_position != null ? player.player_position.name : "-" }}
                </small>
              </div>
            </div>
            <div class="d-flex align-items-baseline flex-row " style="gap: 1rem">
              <p class="m-0 text-center" style="min-width: 2rem">
                {{ player.total_score }}
              </p>
              <div class="p-3 bg-warning">{{ player.total_yellow_card }}</div>
              <div class="p-3 bg-danger text-white">{{ player.total_red_card }}</div>
              <div class="">
                <input
                  class=""
                  type="checkbox"
                  :value="true"
                  :checked="player.already_attendance"
                  :disabled="true"
                  id="defaultCheck1"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div v-for="(player, index) in arr_player2" :key="index">
          <div
            class="d-flex flex-column-reverse flex-md-row flex-fill align-items-end align-items-md-center mt-3"
            style="gap: 0.5rem"
          >
            <div class="d-flex align-items-baseline flex-row " style="gap: 1rem">
              <div class="">
                <input
                  class=""
                  type="checkbox"
                  :value="true"
                  :checked="player.already_attendance"
                  :disabled="true"
                  id="defaultCheck1"/>
              </div>
              <div class="p-3 bg-danger text-white">{{ player.total_red_card }}</div>
              <div class="p-3 bg-warning">{{ player.total_yellow_card }}</div>
              <p class="m-0 text-center" style="min-width: 2rem">
                {{ player.total_score }}
              </p>
            </div>

            <div
              class="d-flex align-items-baseline flex-fill justify-content-end"
              style="gap: 0.5rem"
            >
              <div class="d-flex flex-column align-items-end">
                <p class="m-0 font-weight-bold text-primary">
                  {{ player.name }}
                </p>
                <small class="m-0">
                  {{ player.player_position != null ? player.player_position.name : "-" }}
                </small>
              </div>
              <p class="m-0 text-center" style="min-width: 2rem">
                {{ player.back_number }}
              </p>
            </div>

            <div
              class="d-flex align-items-center justify-content-center"
              style="width: 4rem; height: 4rem"
            >
              <img
								:alt="'opp player image ' + (index + 1)"
                :src="player.url_image"
                class="mw-100 mh-100"
              />
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import NoImage from "@/assets/no_image.png";

export default {
  props: ["arr_match_event", "match", "user"],
  components: {},
  data() {
    return {
      base: null,
      arr_player1: [],
      arr_player2: [],
    };
  },
  watch: {
    match(val){
      if(val.id != null){
        this.get_data(val.registration_event1.id, 'team1')
        this.get_data(val.registration_event2.id, 'team2')
      }
    },
  },
  async mounted() {
    this.base = new Base();

    if(this.match.id != null){
      this.get_data(this.match.registration_event1.id, 'team1')
      this.get_data(this.match.registration_event2.id, 'team2')
    }
  },
  methods: {
    async get_data(registration_event_id, type = 'team1') {
      var response = await this.base.request(this.base.url_api + '/event/registration/player/all?registration_event_id=' + registration_event_id + '&match_id=' + this.match.id)

      if (response != null) {
        if (response.status == "success") {
          for (let player of response.data) {
            var total_score = 0
            var total_yellow_card = 0
            var total_red_card = 0
            for(let event of this.arr_match_event){
              if(event.registration_event_player.id == player.id){
                if(event.type == 'score')
                  total_score++
                else if(event.type == 'yellow_card')
                  total_yellow_card++
                else if(event.type == 'red_card')
                  total_red_card++
                break
              }
            }
            player.total_score = total_score
            player.total_yellow_card = total_yellow_card
            player.total_red_card = total_red_card
            player.is_best_player = this.match.best_player != null && this.match.best_player.id == player.id
            player.url_image = player.file_name != null ? this.base.host + '/media/registration/player?file_name=' + player.file_name : NoImage
          }
          if(type == 'team1')
            this.arr_player1 = response.data
          else if(type == 'team2')
            this.arr_player2 = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  },
};
</script>

<style lang="scss">
.matchEventGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
</style>

<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail :title="$t('sponsorship')"/>
    </div>

    <div class="my-5 custom-navbar-padding-left custom-navbar-padding-right">
      <p class="m-0 text-primary text-center supporting-title">SPONSORSHIP INFORMATION</p>
      <p class="m-0 mt-3 p-spacing gray-777" v-html="description"></p>
    </div>

    <div class="py-5 bg-gray22 custom-navbar-padding-left custom-navbar-padding-right">
      <div class="row align-items-stretch">
        <div class="col-12 col-md-6">
          <div class="card h-100">
            <div class="card-body p-5 bg-warning">
              <p class="m-0 text-primary supporting-title">MAIN SPONSOR</p>
              <p class="m-0 p-spacing mt-3" style="color: var(--primary)" v-html="main_sponsor"></p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 mt-5 mt-md-0">
          <div class="card h-100">
            <div class="card-body p-5 bg-warning">
              <p class="m-0 text-primary supporting-title">OFFICIAL PARTNER</p>
              <p class="m-0 p-spacing mt-3" style="color: var(--primary)" v-html="official_partner"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-5 custom-navbar-padding-left custom-navbar-padding-right">
      <div class="row align-items-stretch">
        <div class="col-12 col-md">
          <p class="m-0 text-primary supporting-title">Why Sponsorship Us?</p>
          <div class="mt-3">
            <div class="card mb-3" v-for="(why, index) in arr_why" :key="index">
              <div class="card-body p-5 bg-warning">
                <p class="m-0 text-primary sponsor-why-title">{{ why.title }}</p>
                <p class="m-0 sponsor-why-detail p-spacing mt-3" v-html="why.subtitle"></p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md">
          <p class="mt-5 mt-md-0 text-primary supporting-title">Reason become our partner as sponsorship</p>

          <p class="m-0 sponsor-reason-detail p-spacing mt-3" v-html="reason_description"></p>

          <div class="mt-3" v-for="(reason, index) in arr_reason" :key="index">
            <p class="m-0 text-primary sponsor-reason-title">{{ reason.title }}</p>
            <p class="m-0 sponsor-reason-detail p-spacing mt-3 text-justify" v-html="reason.subtitle"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="py-5 custom-navbar-padding-left custom-navbar-padding-right">
      <p class="m-0 text-primary text-center supporting-title">Benefit sponsorship us?</p>
      <div class="row my-5 justify-content-center">
        <div class="col-6 col-md-4 col-lg-3 mb-3 px-4" v-for="(benefit, index) in arr_benefit" :key="index">
        <!-- <div class="col-6 col-md-4 col-lg-3 mb-3" v-for="(benefit, index) in arr_benefit" :key="index"> -->
          <div class="w-100">
            <div class="bg-warning" style="width: 100%; padding-top: 100%; position: relative;">
              <div class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center" style="top: 0;">
                <img :src="benefit.image" class="w-100" :alt="'benefit image ' + (index + 1)">
              </div>
            </div>
          </div>

          <div class="mt-3">
            <p class="m-0 text-primary text-center sponsor-reason-title">{{ benefit.title }}</p>
            <p class="m-0 text-center sponsor-reason-detail mt-1" v-html="benefit.subtitle"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

import NavbarDetail from '@/layout/navbar_detail.vue'

import SponsorshipBenefit1 from '@/assets/sponsorship_benefit1.png'
import SponsorshipBenefit2 from '@/assets/sponsorship_benefit2.png'
import SponsorshipBenefit3 from '@/assets/sponsorship_benefit3.png'
import SponsorshipBenefit4 from '@/assets/sponsorship_benefit4.png'
import SponsorshipBenefit5 from '@/assets/sponsorship_benefit5.png'
import SponsorshipBenefit6 from '@/assets/sponsorship_benefit6.png'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
  },
  data(){
    return{
      base: null,
      scrollY: 0,
      arr_factor: [false, false, false, false, ],
      flag: {
        aboutUsTitle1Flag: false,
        aboutUsImage1Flag: false,
        aboutUsContent1Flag: false,
        aboutUsTitle2Flag: false,
        aboutUsImage2Flag: false,
        aboutUsContent2Flag: false,
        aboutUsTitle3Flag: false,
        aboutUsImage3Flag: false,
        aboutUsContent3Flag: false,
      },
      description: 'You are interested in sponsorship or charitable donations, please contact us and a member of the team will get back to you promptly. We are always grateful for offers of support and whilst you may think your contribution might not make a difference, try us – you’d be surprised! Alternatively, do you want to start something special?  If you have an interest in creating new national primary school activity, through festival sponsorship, then we would love to hear from you! Student Open are always looking for ways to enhance the services we already offer. If you think you are able to help us with this, or if you have views about what may help, then please email us.',
      main_sponsor: 'The sponsor of an event provides financial assistance. For companies that collaborate as main sponsors, we provide for package: Premium, Platinum, Enterprise, Gold, Silver and Bronze.',
      official_partner: 'The sponsor provides equipment or products that assist organizers in reducing operation costs, such as: Food and drink, Venue or Field, Competition Equipment, Officer uniform, Medic Officer, Broadcast, and Merchandise or scholarship to winner.',
      reason_description: 'Partnering with us can mean anything from simple event sponsorship to long-term collaboration. Joining forces with Student Open delivers our partners tangible benefits.',
      arr_reason: [
        {
          id: "1",
          title: `Engage New audience`,
          subtitle: `Is there an audience that the Student Open can help you reach? Partner with the Student Open and we can help you engage with shared target audiences, such as students or young professionals. When you sponsor an event, exhibition or programme through the Student Open, you will benefit from our full online and offline marketing infrastructure and expertise.`,
        },
        {
          id: "1",
          title: `Enhance your brand`,
          subtitle: `The Student Open brand is trusted all over the world and represents the best the Indonesia has to offer. Our brand is based on valuing people, integrity, mutuality, creativity and professionalism. These values underpin everything we do. Whether you are looking to sponsor, co-brand or become an official partner, we can add value to your brand. Through channels including events, online campaigns and social media, we can also promote your brand nationwide and internationally.`,
        },
        {
          id: "1",
          title: `Achieve  your CSR aims`,
          subtitle: `We can tap our expertise in running socially-minded programmes to help you achieve your goals for Corporate Social Responsibility (CSR). From developing and promoting a programme to building relationships and gaining employee buy-in, we will act as a full partner to ensure your efforts are effective. An important strand of our CSR partnerships is engaging and motivating staff at our partner companies. With our globally trusted brand, we are also able to promote your CSR profile both nationwide and internationally.`,
        },
      ],
      arr_why: [
        {
          id: "1",
          title: `7 SPORT IN OUR COMPETITION`,
          subtitle: `We reach the fans of all Student Open participant, we engage with a wider fanbase than any individual club.`,
        },
        {
          id: "1",
          title: `MUCH MORE THAN SPORT EVENT.`,
          subtitle: `Not only organizing sports matches. we also form a niche market that the public and sports lovers can relate.`,
        },
        {
          id: "1",
          title: `MORE PARTICIPANT IN INDONESIA`,
          subtitle: `The large number of sports that are competed in at student age certainly makes parents and siblings happy to come to support them.`,
        },
        {
          id: "1",
          title: `FLEXIBILITY AND TAILORMADE.`,
          subtitle: `We’d be happy to define a tailor-made proposal according to our partner’ needs, strategy and business objectives.`,
        },
      ],
      arr_benefit: [
        {
          id: "1",
          image: SponsorshipBenefit1,
          title: `TOURNAMENT SIGNAGE`,
          subtitle: `Presence within the football tournament and on staff wear and apparel`,
        },
        {
          id: "1",
          image: SponsorshipBenefit2,
          title: `BOOTH SPACE`,
          subtitle: `Space for product placement and product sampling opportunity.`,
        },
        {
          id: "1",
          image: SponsorshipBenefit3,
          title: `BACKDROP`,
          subtitle: `Presence within the official tournament backdrop and photocall.`,
        },
        {
          id: "1",
          image: SponsorshipBenefit4,
          title: `OFFICIAL ANNOUCEMENT`,
          subtitle: `Presence within the official tournament annoucement.`,
        },
        {
          id: "1",
          image: SponsorshipBenefit5,
          title: `MENTION IN PR CONTENT`,
          subtitle: `Presence at relevant Student Open content distributed to the media.`,
        },
        {
          id: "1",
          image: SponsorshipBenefit6,
          title: `INVITATION  MEDIA DAY`,
          subtitle: `Access to the Student Open media day through 1 on 1 interviews, media grathering events, etc.`,
        },
      ],
    }
  },
  watch: {
    arr_factor(val){
      this.$emit('onChangeArrFactor', val)
      this.manage_start_animation()
    },
    scrollY(){
      this.manage_start_animation()
    },
  },
  created(){
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1
    // this.arr_factor = [true, true, true, true, ]

    this.get_sponsorship_info()
    this.get_benefit()
    this.get_why()
    this.get_reason()
  },
  methods: {
    onImageLoad(index){
      var team = this.arr_team[index]
      team.is_image_loaded = true
      this.$set(this.arr_team, index, team)
    },
    manage_start_animation(){
      var context = this
      this.flag.aboutUsTitle1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsTitle1Flag, this.arr_factor, 0)
      this.flag.aboutUsImage1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsImage1Flag, this.arr_factor, 0)
      this.flag.aboutUsContent1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsContent1Flag, this.arr_factor, 0)

      setTimeout(() => {
        if(context.flag.aboutUsContent1Flag){
          var margin = window.$('#about-us-content').height()
          context.flag.aboutUsTitle2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle2Flag, context.arr_factor, 0, margin - margin)
          context.flag.aboutUsContent2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent2Flag, context.arr_factor, 0, margin - 300)

          if(context.flag.aboutUsContent2Flag){
            context.flag.aboutUsImage2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage2Flag, context.arr_factor, 0, margin + window.$('#trust-content').innerHeight() - 300)

            if(context.flag.aboutUsImage2Flag){
              margin += window.$('#trust-container').innerHeight() - 950
              context.flag.aboutUsTitle3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsImage3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsContent3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent3Flag, context.arr_factor, 800, margin)
            }
          }
        }
      }, 200)

    },
    handleScroll(){
      this.scrollY = window.scrollY
    },
    async get_sponsorship_info(){
      var response = await this.base.request(this.base.url_api + `/info?arr_type=["sponsorship_description","sponsorship_main_sponsor","sponsorship_official_partner","sponsorship_reason_description"]`)
      this.$set(this.arr_factor, 0, true)

      if(response != null){
        if(response.status === "success"){
          for(let info of response.data){
            if(info.type == "sponsorship_description")
              this.description = info.content
            else if(info.type == "sponsorship_main_sponsor")
              this.main_sponsor = info.content
            else if(info.type == "sponsorship_official_partner")
              this.official_partner = info.content
            else if(info.type == "sponsorship_reason_description")
              this.reason_description = info.content
          }
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_benefit(){
      var response = await this.base.request(this.base.url_api + "/sponsorship/benefit/all")
      this.$set(this.arr_factor, 1, true)

      if(response != null){
        if(response.status === "success"){
          for(let benefit of response.data){
            benefit.image = benefit.file_name != null ? this.base.host + "/media/sponsorship/benefit?file_name=" + benefit.file_name : null
            benefit.subtitle = benefit.content
          }
          this.arr_benefit = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_reason(){
      var response = await this.base.request(this.base.url_api + "/sponsorship/reason/all")
      this.$set(this.arr_factor, 2, true)

      if(response != null){
        if(response.status === "success"){
          for(let reason of response.data){
            reason.subtitle = reason.content
          }
          this.arr_reason = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_why(){
      var response = await this.base.request(this.base.url_api + "/sponsorship/why/all")
      this.$set(this.arr_factor, 3, true)

      if(response != null){
        if(response.status === "success"){
          for(let why of response.data){
            why.subtitle = why.content
          }
          this.arr_why = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
.custom-title{
  color: $primary;
  font-family: poppins-bold;
}
.sponsor-detail{
  font-family: montserrat-light;
  color: $gray20;
}
.sponsor-detail1{
  font-family: montserrat-light;
  color: $primary;
}
.sponsor-why-title{
  // font-family: montserrat-light;
  color: $primary;
  font-size: 2rem;
}
.sponsor-why-detail{
  // font-family: montserrat-light;
  color: $primary;
}
.sponsor-reason-title{
  font-family: montserrat-bold;
  color: $primary;
}
.sponsor-reason-detail{
  font-family: montserrat-light;
  color: $gray20;
}
.about-us-medal-detail{
  color: $gray20;
}
.bg-gray22{
  background-color: $gray22;
}
.trust-card{
  background-color: $gray9;
  border: none;
}
.title-section{
  font-size: 2rem;
  font-family: poppins-medium;
}
.content-section{
  color: $gray6;
}
.team-role{
  color: $black1;
}
.team-name{
  color: $black1;
  font-family: poppins-bold;
}
.about-us-title1-enter-active, .about-us-title1-leave-active,
.about-us-title2-enter-active, .about-us-title2-leave-active,
.about-us-title3-enter-active, .about-us-title3-leave-active{
  transition: all 2s;
}
.about-us-title1-leave-to, .about-us-title1-enter,
.about-us-title2-leave-to, .about-us-title2-enter,
.about-us-title3-leave-to, .about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}
.about-us-content1-enter-active, .about-us-content1-leave-active,
.about-us-content2-enter-active, .about-us-content2-leave-active,
.about-us-content3-enter-active, .about-us-content3-leave-active{
  transition: all 2s;
}
.about-us-content1-leave-to, .about-us-content1-enter,
.about-us-content2-leave-to, .about-us-content2-enter,
.about-us-content3-leave-to, .about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}
.about-us-image1-enter-active, .about-us-image1-leave-active,
.about-us-image2-enter-active, .about-us-image2-leave-active,
.about-us-image3-enter-active, .about-us-image3-leave-active{
  transition: all 2s;
}
.about-us-image1-leave-to, .about-us-image1-enter,
.about-us-image2-leave-to, .about-us-image2-enter,
.about-us-image3-leave-to, .about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}
#about-us-content img{
  width: 100%;
  border-radius: 1rem;
}
.trust-list > p:nth-child(1){
  min-height: 100px;
  text-align: center;
}
.trust-list > p:nth-child(2){
  text-align: justify;
  text-align-last: center;
}
</style>

<template>
  <div class="row h-100 position-absolute w-100" style="top: 0;">
    <div class="col-6 position-relative h-100">
      <div class="w-100 h-100 auth-col">
        <img src="@/assets/auth_logo.png" class="position-absolute" style="bottom: 0;" alt="auth logo">
      </div>
    </div>

    <div class="col-6 h-100">
      <div class="auth-col1">
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

export default {
  components: {
  },
  props: [],
  data(){
    return{
      base: null,
    }
  },
  created(){
    this.base = new Base()
  },
  methods:{
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-right: .5rem;
  }
  .article-second{
    padding-left: .5rem;
  }
}
@media only screen and (min-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-left: .5rem;
  }
  .article-second{
    padding: 0 .5rem;
  }
}

.auth-col{
  background-color: $primary;
}
.auth-col1{
  background-color: $gray12;
}

.supporting-title{
  font-family: 'montserrat-bold';
  font-size: 2rem;
}
.navbar-detail-subtitle{
  font-family: 'montserrat-medium';
}
.navbar-detail-card2{
  background-color: $blue5;
  width: 100%;
}
.home-article-title1-enter-active, .home-article-title1-leave-active{
  transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
  transform: translateY(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
  transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
  transform: translateY(-10rem);
  opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
  transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
  transform: translateX(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
</style>

<template>
  <div>
    <div>
      <div v-if="this.$route.path.match(/\/registration*/g)" class="h-100">
        <router-view />
      </div>
			<div v-else-if="this.$route.path.match(/\/export*/g)" class="h-100">
				<router-view />
			</div>
      <div v-else-if="this.$route.path.match(/\/auth*/g)" class="min-vh-100">
        <router-view />
      </div>
      <div id="app" v-else>
        <!-- <img src="@/assets/background_schedule.png" class="position-absolute" style="right: 0; z-index: -1;"/> -->
        <div id="content">
          <div ref="navbar_top">
            <navbar @onNavbarToggle="onNavbarToggle" />
          </div>
          <Transition name="navbar-fixed">
            <div class="position-fixed w-100" style="z-index: 1; top: 0" v-show="flag.navbar">
              <navbar :scrollY="scrollY" @onNavbarToggle="onNavbarToggle" />
            </div>
          </Transition>
          <side-navbar-mobile :isNavbarToggle="isNavbarToggle" style="" />
          <div ref="body-content" style="min-height: calc(100vh - 94px);">
            <router-view @onChangeArrFactor="(arr) => onChangeArrFactor(arr)" />
          </div>
        </div>
        <footer1 v-show="this.$route.path != '/sell'" />



        <div id="login-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="login-modal-title"
          aria-hidden="true">
          <div class="modal-dialog modal-xl justify-content-center modal-dialog-centered" style="pointer-events: unset;">
            <div class="model-content">
              <Login />
            </div>
          </div>
        </div>

        <div id="register-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="register-modal-title"
          aria-hidden="true">
          <div class="modal-dialog modal-xl justify-content-center modal-dialog-centered" style="pointer-events: unset;">
            <div class="model-content">
              <Register />
            </div>
          </div>
        </div>

        <div id="forget-password-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="forget-password-modal-title"
          aria-hidden="true">
          <div class="modal-dialog modal-xl justify-content-center modal-dialog-centered" style="pointer-events: unset;">
            <div class="model-content">
              <ForgetPassword />
            </div>
          </div>
        </div>

        <div id="forget-password-success-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="forget-password-success-modal-title"
          aria-hidden="true">
          <div class="modal-dialog modal-xl justify-content-center modal-dialog-centered" style="pointer-events: unset;">
            <div class="model-content">
              <ForgetPasswordSuccess />
            </div>
          </div>
        </div>

				<div id="change-category-sport-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="change-category-sport-modal-title"
					aria-hidden="true">
					<div class="modal-dialog modal-xl justify-content-center modal-dialog-centered" style="pointer-events: unset;">
						<div class="model-content">
							<ChangeCategorySport />
						</div>
					</div>
				</div>

        <div id="change-profile-modal" v-if="user != null && user.id != null && user.type.name == 'coordinator'" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="change-profile-modal-title"
          aria-hidden="true">
          <div class="modal-dialog modal-xl justify-content-center modal-dialog-centered" style="pointer-events: unset;">
            <div class="model-content">
              <ChangeProfile />
            </div>
          </div>
        </div>

        <div id="change-password-modal" v-if="user != null && user.id != null && user.type.name == 'coordinator'" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="change-password-modal-title"
          aria-hidden="true">
          <div class="modal-dialog modal-xl justify-content-center modal-dialog-centered" style="pointer-events: unset;">
            <div class="model-content">
              <ChangePassword />
            </div>
          </div>
        </div>

        <custom-loading :arr_factor="arr_factor" />
        <please-wait-modal />
        <under-development-modal />
      </div>
    </div>

    <div class="position-fixed" v-if="!this.$route.path.match(/\/export*/g)" style="right: 3rem; bottom: 3rem;">
      <a href="https://wa.link/u3dbom" target="__blank" class="btn btn-primary rounded-circle d-flex align-items-center justify-content-center" style="aspect-ratio: 1; width: 4rem;">
				<img src="@/assets/whitelogo-whatsapp-png-46043.png" style="width: 2rem;" alt="whatsapp link" />
			</a>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
	import momentTZ from 'moment-timezone'
import Base from '@/utils/base'
import packageJSON from '../package.json'

import footer1 from './layout/footer'
import navbar from './layout/navbar'
import SideNavbarMobile from './layout/side_navbar_mobile'
import CustomLoading from '@/layout/custom_loading'
import PleaseWaitModal from '@/layout/please_wait_modal.vue'
import UnderDevelopmentModal from '@/layout/under_development_modal.vue'

import login from '@/pages/auth/login.vue'
import change_category_sport from '@/pages/auth/change_category_sport.vue'
import register from '@/pages/auth/register.vue'
import forget_password from '@/pages/auth/forget_password.vue'
import forget_password_success from '@/pages/auth/forget_password_success.vue'
import change_profile from '@/pages/auth/change_profile.vue'
import change_password from '@/pages/auth/change_password.vue'

export default {
  components: {
    footer1, navbar,
    'side-navbar-mobile': SideNavbarMobile,
    'custom-loading': CustomLoading,
    'please-wait-modal': PleaseWaitModal,
    'under-development-modal': UnderDevelopmentModal,
    "Login": login,
    "Register": register,
    "ForgetPassword": forget_password,
    "ForgetPasswordSuccess": forget_password_success,
    "ChangeProfile": change_profile,
    "ChangePassword": change_password,
		"ChangeCategorySport": change_category_sport,
  },
  data() {
    return {
      base: null,
      scrollY: 0,
      flag: {
        navbar: false,
      },
      isNavbarToggle: false,
      arr_factor: [],
      user: {},
    }
  },
  watch: {
    scrollY(val) {
      // console.log(this.base.responsive_scroll_threshold(500))
      this.flag.navbar = val >= this.base.responsive_scroll_threshold(200)
    },
  },
  async created() {
    // $(function () {
    //   $('[data-toggle="popover"]').popover()
    // })
    this.base = new Base()

		// this.check_version()
    // window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1

    var user = await window.localStorage.getItem('user')
    this.user = JSON.parse(user)

		var token = await window.localStorage.getItem('token')
    var token_expired = await window.localStorage.getItem('token_expired')
    if(token_expired != null)
      token_expired = moment(token_expired, 'YYYY-MM-DD HH:mm:ss')

    if(token_expired != null && token_expired.isBefore(moment())){
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token_expired')
      return
    }
		else if(token != null && token_expired == null){
			window.localStorage.removeItem('token')
			window.localStorage.removeItem('user')
			window.localStorage.removeItem('token_expired')

			window.location.reload()
		}

		this.reload_new_version()
    // const last_version = localStorage.getItem('version')
    // if (last_version == null || (last_version != null && last_version !== this.base.app_version)) {
    //   localStorage.setItem('version', this.base.app_version)
    //   window.location.reload(true);
    // }
  },
  methods: {
		async reload_new_version(){
			var last_version = await localStorage.getItem('version')
			try{
				if(last_version != null)
					last_version = JSON.parse(last_version)
				else
					last_version = {}
			} catch(e) {
				last_version = {}
			}


			if(last_version[this.$route.path] == null || (last_version[this.$route.path] != null && last_version[this.$route.path] != packageJSON.version)){
				last_version[this.$route.path] = packageJSON.version
				localStorage.setItem('version', JSON.stringify(last_version))
				window.location.reload(true)
			}
		},
		async check_version(){
			var version = await window.localStorage.getItem('version')
			if(version != null){
				window.localStorage.setItem('version', this.base.version)
				window.location.reload()
			}
			else if(version != this.base.version){
				window.localStorage.setItem('version', this.base.version)
				window.location.reload()
			}
		},
    onChangeArrFactor(arr) {
      this.arr_factor = arr
    },
    onNavbarToggle() {
      this.isNavbarToggle = !this.isNavbarToggle
    },
    handleScroll() {
      this.scrollY = window.scrollY
    }
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 576px) {

  html,
  body {
    font-size: 12px;
  }

  .custom-navbar-padding {
    padding: 1.8rem 2rem;
  }

  .custom-navbar-padding-right {
    padding-right: 2rem;
  }

  .custom-navbar-padding-left {
    padding-left: 2rem;
  }

  .content-container {
    width: 100%;
    padding: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 960px) {

  html,
  body {
    font-size: 12px;
  }

  .custom-navbar-padding {
    padding: 1.8rem 3rem;
  }

  .custom-navbar-padding-right {
    padding-right: 3rem;
  }

  .custom-navbar-padding-left {
    padding-left: 3rem;
  }

  .content-container {
    width: 100%;
    padding: 0 1rem;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1517px) {

  html,
  body {
    font-size: 16px;
  }

  .custom-navbar-padding {
    padding: 1.8rem 10rem;
  }

  .custom-navbar-padding-right {
    padding-right: 10rem;
  }

  .custom-navbar-padding-left {
    padding-left: 10rem;
  }

  .content-container {
    width: $content-width;
    display: inline-block;
  }
}

@media only screen and (min-width: 1517px) {

  html,
  body {
    font-size: 18px;
  }

  .custom-navbar-padding {
    padding: 1.8rem 10rem;
  }

  .custom-navbar-padding-right {
    padding-right: 10rem;
  }

  .custom-navbar-padding-left {
    padding-left: 10rem;
  }

  .content-container {
    width: $content-width;
    display: inline-block;
  }
}

html,
body {
  font-family: montserrat-regular;
  overflow-x: hidden;
}

// table *{
//   border: inherit;
// }
table p {
  margin: 0;
}

.form-control {
  height: 3rem;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.general-title {
  font-family: poppins-bold;
  font-size: 2rem;
  text-transform: uppercase;
}

.general-subtitle {
  font-family: poppins-regular;
  font-size: 1.8rem;
}

.navigation {
  color: $gray8;
}

.navigation.navigation-now {
  font-family: poppins-medium;
  // text-decoration: underline;
  text-transform: uppercase;
}

.select2-container .select2-selection--single {
  height: 2.3rem;
}

.select2-selection__arrow {
  height: 100% !important;
}

.select2-selection__rendered {
  height: 100% !important;
  display: flex !important;
  align-items: center;
  line-height: 100% !important;
}

.select2-results {
  z-index: 1;
  background-color: white;
}

.navbar-fixed-enter-active,
.navbar-fixed-leave-active {
  transition: all .5s;
}

.navbar-fixed-leave-to,
.navbar-fixed-enter {
  margin-top: -10rem;
  opacity: 0;
}

.ql-size-small {
  font-size: 1.2rem !important;
}

.ql-size-large {
  font-size: 4.5rem !important;
}

.ql-size-huge {
  font-size: 5.25rem !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  content: 4.5rem !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  content: 5.25rem !important;
}


.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  font-size: 4.5rem !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  font-size: 5.25rem !important;
}

.form-control:focus {
  border: 1px solid $gray13;
  box-shadow: none;
}

.form-control,
.select2-results__option {
  font-size: 1rem;
}

.select2-selection__rendered {
  padding-top: .2rem;
  font-size: 1rem;
}

.modal.fade.show{
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
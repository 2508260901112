<template>
  <div class="">

    <div class="my-3 d-flex align-items-center">
			<a href="#" @click="$emit('onBackClicked')">
				<font-awesome-icon :icon="['fas', 'arrow-left']" class="text-primary" style="font-size: 1.5rem;"/>
			</a>
			<p class="m-0 competition-detail-title ml-3">Edit Quiz {{ name }}</p>
		</div>

		<div>

			<div class="d-flex">
				<div class="py-1 px-3 mr-1 flex-fill" style="cursor: pointer;" @click="onTabClicked(0)" :class="{'bg-primary text-white': selected_tab == 0, 'tab-disabled': selected_tab != 0,}">
					<p class="m-0 text-center">General Info</p>
				</div>

				<div class="py-1 px-3 ml-1 flex-fill" style="cursor: pointer;" @click="onTabClicked(1)" :class="{'bg-primary text-white': selected_tab == 1, 'tab-disabled': selected_tab != 1,}">
					<p class="m-0 text-center">Questions</p>
				</div>
			</div>

			<div class="mt-3">
				<GeneralInfo v-if="selected_tab == 0" :selected_tab="selected_tab" :is_change_tab="is_change_tab" :is_submit="is_submit" :quiz_data="quiz_data" :tab_type="tab_type" @onValidate="onValidate"/>
				<ListQuestion v-else-if="selected_tab == 1" :selected_tab="selected_tab" :is_change_tab="is_change_tab" :is_submit="is_submit" :quiz_data="quiz_data" :tab_type="tab_type" @onValidate="onValidate"/>
			</div>

		</div>

		<div class="d-flex justify-content-between align-items-center mt-3">
			<button class="btn btn-outline-primary" @click="onPrevClicked" style="max-width: 10rem; width: 100%;">{{ selected_tab == 0 ? 'Cancel' : 'Previous' }}</button>
			<button class="btn btn-primary" @click="onNextClicked" style="max-width: 10rem; width: 100%;">{{ selected_tab == 1 ? 'Submit' : 'Next' }}</button>
		</div>

  </div>
</template>

<script>
import Base from "@/utils/base";
import momentTZ from "moment-timezone";

import GeneralInfo from "@/pages/competition/component/quiz/edit/general_info";
import ListQuestion from "@/pages/competition/component/quiz/edit/list_question";

export default {
  props: ['competition_data', 'match_id', 'name', ],
	components: {
		GeneralInfo: GeneralInfo,
		ListQuestion: ListQuestion,
	},
  data() {
    return {
      base: null,
			quiz_data: {},
			selected_tab: 0,
			selected_tab_temp: 0,
			is_submit: false,
			is_change_tab: false,
			is_complete: false,
			tab_type: 'next',
    };
  },
	watch: {
		is_complete(val){
			if(val){
				if(this.tab_type == 'prev')
					this.selected_tab--
				else if(this.tab_type == 'next'){
					if(this.selected_tab < 1)
						this.selected_tab++
					else
						this.submit()
				}
				else if(this.tab_type == 'custom')
					this.selected_tab = this.selected_tab_temp
				this.is_complete = false
			}
		},
	},
  async created() {
    this.base = new Base()

		this.get_data()
  },
  methods: {
		onPrevClicked(){
			if(this.selected_tab > 0){
				this.tab_type = 'prev'
				this.is_change_tab = true
			}
			else
				this.$emit('onBackClicked')
		},
		onNextClicked(){
			this.tab_type = 'next'
			this.is_change_tab = true
		},
		onTabClicked(selected_tab){
			// this.selected_tab = selected_tab
			// this.is_change_tab = true

			this.tab_type = 'custom'
			this.selected_tab_temp = selected_tab
			this.is_change_tab = true
		},
		async get_data() {
			window.$('#please_wait_modal').modal('show')

			var response = await this.base.request(
				this.base.url_api + "/quiz?match_id=" + this.match_id
			);

			setTimeout(() => {
				window.$('#please_wait_modal').modal('hide')
			}, 500)


			if (response != null) {
				if (response.status == "success") {
					for(let question of response.data[0].question){
						for(let option of question.option){
							if(option.file_name != null && option.file_name != '')
								option.image_data = {
									image_display: this.base.host + '/media/quiz/option?file_name=' + option.file_name,
									data_image: null,
								}
						}
						if(question.file_name != null && question.file_name != '')
							question.image_data = {
								image_display: this.base.host + '/media/quiz/question?file_name=' + question.file_name,
								data_image: null,
							}
					}

					response.data[0].num_question = response.data[0].question.length
					this.quiz_data = response.data[0]
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
		async submit(){
			var data = JSON.parse(JSON.stringify(this.quiz_data))
			for(let question of data.question){
				for(let option of question.option){
					if(option.image_data != null && option.image_data.image_display != null && option.image_data.image_display.includes('blob')){
						var img_response = await this.base.toDataURLPromise(option.image_data.image_display)
						option.image_data.file = img_response
					}
				}
				question.arr_option = question.option

				if(question.image_data != null && question.image_data.image_display != null && question.image_data.image_display.includes('blob')){
					img_response = await this.base.toDataURLPromise(question.image_data.image_display)
					question.image_data.file = img_response
				}
			}
			data.arr_question = data.question

			window.$('#please_wait_modal').modal('show')
			var response = await this.base.request(this.base.url_api + "/quiz/edit", 'post', data)
			setTimeout(() => {
				window.$('#please_wait_modal').modal('hide')
			}, 500)


			if (response != null) {
				if (response.status == "success") {
					this.$emit('onBackClicked')
				}
				else
					this.base.show_error(response.message);
			}
			else
				console.log(this.$t("server_error"));
		},
		onValidate(message, obj){
			if(message != '')
				this.base.show_error(message)
			else{
				var quiz_data = JSON.parse(JSON.stringify(this.quiz_data))
				for(let x in obj)
					quiz_data[x] = obj[x]

				this.quiz_data = quiz_data
				this.is_complete = true
			}
			this.is_change_tab = false
		},
  },
};
</script>

<style lang="scss">
	.tab-disabled{
		background-color: $gray24;
		color: $gray25;
	}
	@media only screen and (max-width: 1517px) {
		.quiz-question-item-label{
			width: 50%;
		}
		.quiz-question-item-input{
			width: 50%;
		}
	}
	@media only screen and (min-width: 1517px) {
		.quiz-question-item-label{
			width: 30%;
		}
		.quiz-question-item-input{
			width: 60%;
		}
	}
</style>

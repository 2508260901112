<template>
  <div class="">
    <div>
      <img :src="image_banner" class="w-100" :alt="'banner image'">
    </div>

    <div>
      <OurCompetition @onCompleteRequest="onCompleteRequest"/>
    </div>

    <div>
      <Supporting @onCompleteRequest="onCompleteRequest"/>
    </div>

    <div>
      <CheckCertificate @onCompleteRequest="onCompleteRequest"/>
    </div>

    <div>
      <OurActivity @onCompleteRequest="onCompleteRequest"/>
    </div>

    <div>
      <Sponsor @onCompleteRequest="onCompleteRequest"/>
    </div>

    <div>
      <iframe src="https://cdn.lightwidget.com/widgets/917991e4897a5bb2bf2e8e2822781672.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;" title="instagram feed"></iframe>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

// import BannerHome from '@/assets/banner_home.png';

import Audi from '@/assets/Audi.png';
import BMW from '@/assets/BMW.png';
import Ford from '@/assets/Ford.png';
import Mazda from '@/assets/Mazda.png';
import Mercedes from '@/assets/Mercedes Benz.png';
import Nissan from '@/assets/Nissan.png';
import Volkswagen from '@/assets/Volkswagen.png';
import BannerHome from '@/assets/banner_home.png';

import OurCompetition from '@/pages/home/component/our_competition.vue'
import Supporting from '@/pages/home/component/supporting.vue'
import CheckCertificate from '@/pages/home/component/check_certificate.vue'
import OurActivity from '@/pages/home/component/our_activity.vue'
import Sponsor from '@/pages/home/component/sponsor.vue'

export default {
  components: {
    'OurCompetition': OurCompetition,
    'Supporting': Supporting,
    'CheckCertificate': CheckCertificate,
    'OurActivity': OurActivity,
    'Sponsor': Sponsor,
  },
  data(){
    return{
      base: null,
      flag: {
        homeBanner: false,
        homeSearch: false,
        recommendationTitle1: false,
        recommendationTitle2: false,
        testimonyTitle1: false,
        testimonyTitle2: false,
        articleTitle1: false,
        articleTitle2: false,
        whyChooseTitle1: false,
        whyChooseTitle2: false,
        whyChooseUs1: false,
        whyChooseUs2: false,
        whyChooseUs3: false,
      },
      image_banner: BannerHome,
      arr_factor: [false, false, false, false, false, ],
      arr_car_brand: [Audi, BMW, Ford, Ford, Mazda, Mercedes, Nissan, Volkswagen,],
      arr_recommendation: [],
      scrollY: 0,
    }
  },
  watch: {
    arr_factor(val){
      this.$emit('onChangeArrFactor', val)
      this.manage_start_animation()
    },
    scrollY(){
      this.manage_start_animation()
    },
  },
  mounted(){
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1

    if(this.$route.query.type != null && this.$route.query.type == 'logout'){
      window.localStorage.removeItem('token')
      window.location.href = '/'
    }

    this.get_banner()
  },
  methods: {
    handleScroll(){
      this.scrollY = window.scrollY
    },
    manage_start_animation(){
      this.flag.homeBanner = this.base.check_start_animation(this.scrollY, this.flag.homeBanner, this.arr_factor, 0)
      this.flag.homeSearch = this.base.check_start_animation(this.scrollY, this.flag.homeSearch, this.arr_factor, 0)

      var whyChooseImage = window.$('#home-search').innerHeight() - 600
      this.flag.recommendationTitle1 = this.base.check_start_animation(this.scrollY, this.flag.recommendationTitle1, this.arr_factor, 200, whyChooseImage)
      this.flag.recommendationTitle2 = this.base.check_start_animation(this.scrollY, this.flag.recommendationTitle2, this.arr_factor, 200, whyChooseImage)

      whyChooseImage += window.$('#recommendation').innerHeight() - 600
      this.flag.whyChooseTitle1 = this.base.check_start_animation(this.scrollY, this.flag.whyChooseTitle1, this.arr_factor, 1100, whyChooseImage)
      this.flag.whyChooseTitle2 = this.base.check_start_animation(this.scrollY, this.flag.whyChooseTitle2, this.arr_factor, 1100, whyChooseImage)
      this.flag.whyChooseUs1 = this.base.check_start_animation(this.scrollY, this.flag.whyChooseUs1, this.arr_factor, 1150, whyChooseImage)
      this.flag.whyChooseUs2 = this.base.check_start_animation(this.scrollY, this.flag.whyChooseUs2, this.arr_factor, 1200, whyChooseImage)
      this.flag.whyChooseUs3 = this.base.check_start_animation(this.scrollY, this.flag.whyChooseUs3, this.arr_factor, 1250, whyChooseImage)
      this.flag.testimonyTitle1 = this.base.check_start_animation(this.scrollY, this.flag.testimonyTitle1, this.arr_factor, 1100, whyChooseImage)
      this.flag.testimonyTitle2 = this.base.check_start_animation(this.scrollY, this.flag.testimonyTitle2, this.arr_factor, 1100, whyChooseImage)
      this.flag.articleTitle1 = this.base.check_start_animation(this.scrollY, this.flag.articleTitle1, this.arr_factor, 1500, whyChooseImage)
      this.flag.articleTitle2 = this.base.check_start_animation(this.scrollY, this.flag.articleTitle2, this.arr_factor, 1500, whyChooseImage)
    },
    onCompleteRequest(index){
      this.$set(this.arr_factor, index, true)
    },
    async get_banner(){
      var response = await this.base.request(this.base.url_api + `/banner/all?num_data=1`)
      this.$set(this.arr_factor, 0, true)

      if(response != null){
        if(response.status === "success"){
          this.image_banner = response.data.length > 0 && response.data[0].file_name != null ? this.base.host + "/media/banner?file_name=" + response.data[0].file_name : BannerHome
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    onChangeRecommendationArr(arr){
      this.arr_recommendation = arr
    },
    search(){
      this.flag = !this.flag
    },
    onLocationChanged(val){
      console.log(val);
    },
    onLocationSelect({id, text}){
      console.log({id, text})
    },
    onBrandChanged(val){
      console.log(val);
    },
    onBrandSelect({id, text}){
      console.log({id, text})
    },
    onModelChanged(val){
      console.log(val);
    },
    onModelSelect({id, text}){
      console.log({id, text})
    },
  }
}
</script>

<style lang="scss">
.vertical{
  background-color: $gray2;
  width: 1px;
  height: 100%;
}
.slick-prev:before, .slick-next:before {
  background-color: $black !important;
}
.slick-dots {
  bottom: -5rem;
}
.slick-dots button:before {
  color: $gray8 !important; /* color of dots */
  font-size: .8rem !important;
  opacity: 1 !important;
}
.slick-dots .slick-active button:before {
  color: $primary !important;
  font-size: .8rem !important;
  opacity: 1 !important;
}
</style>

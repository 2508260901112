<template>
  <div class="px-lg-5 py-5 w-100 bg-primary">
    <div class="container" style="max-width: 78rem;">
      <p class="m-0 text-warning text-center supporting-title " v-html="$t('supporting_title')" ></p>
      <p class="m-0 text-warning text-center supporting-detail my-3 px-5 px-lg-0">{{ $t("supporting_detail") }}</p>

      <div class="row mt-5 mt-md-0">
        <div class="col-12 col-md-6 col-xl-5 text-center">
          <img src="@/assets/people.png" class="supporting-data-image" style="" :alt="'people image'">
          <div class="row">
            <div class="col">
              <p class="m-0 text-warning text-center supporting-data-title">{{ total_player.toLocaleString(base.locale_string) }}</p>
              <p class="m-0 text-warning text-center supporting-data-detail">{{ $t('player') }}</p>
            </div>
            <div class="col-auto d-flex justify-content-center">
              <div class="supporting_hr h-100"></div>
            </div>
            <div class="col">
              <p class="m-0 text-warning text-center supporting-data-title">{{ total_coach.toLocaleString(base.locale_string) }}</p>
              <p class="m-0 text-warning text-center supporting-data-detail">{{ $t('coach') }}</p>
            </div>
          </div>
        </div>
        <div class="col-xl-2 d-none d-xl-block"></div>
        <div class="col-12 col-md-6 col-xl-5 text-center mt-3 mt-md-0">
          <img src="@/assets/school.png" class="supporting-data-image" style="" :alt="'school image'">
          <div class="row">
            <div class="col">
              <p class="m-0 text-warning text-center supporting-data-title">{{ total_school.toLocaleString(base.locale_string) }}</p>
              <p class="m-0 text-warning text-center supporting-data-detail">{{ $t('school') }}</p>
            </div>
            <div class="col-auto d-flex justify-content-center">
              <div class="supporting_hr h-100"></div>
            </div>
            <div class="col">
              <p class="m-0 text-warning text-center supporting-data-title">{{ total_sport_club.toLocaleString(base.locale_string) }}</p>
              <p class="m-0 text-warning text-center supporting-data-detail" style="white-space: nowrap;">{{ $t('sport_club') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
// import moment from 'moment';

// import Football from '@/assets/football.png';
// import Futsal from '@/assets/futsal.png';
// import Basket from '@/assets/basket.png';
// import Softball from '@/assets/softball.png';
// import Badminton from '@/assets/badminton.png';
// import Tennis from '@/assets/tennis.png';
// import Taekwondo from '@/assets/taekwondo.png';

export default {
  components: {
  },
  props: ['articleTitle1Flag', 'articleTitle2Flag', ],
  data(){
    return{
      base: null,
      total_player: 6312,
      total_coach: 1488,
      total_school: 150,
      total_sport_club: 243,
    }
  },
  watch: {
    isLoading(val){
      this.$emit("onLoading", val, 2)
    },
  },
  created(){
    this.base = new Base()

    this.get_info()
  },
  methods:{
    async get_info(){
      var response = await this.base.request(this.base.url_api + `/info?arr_type=["home_supporting_total_player","home_supporting_total_coach","home_supporting_total_school","home_supporting_total_club"]`)
      this.$emit('onCompleteRequest', 4)

      if(response != null){
        if(response.status === "success"){
          for(let info of response.data){
            if(info.type == "home_supporting_total_player")
              this.total_player = parseFloat(info.content)
            else if(info.type == "home_supporting_total_coach")
              this.total_coach = parseFloat(info.content)
            else if(info.type == "home_supporting_total_school")
              this.total_school = parseFloat(info.content)
            else if(info.type == "home_supporting_total_club")
              this.total_sport_club = parseFloat(info.content)
          }
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-right: .5rem;
  }
  .article-second{
    padding-left: .5rem;
  }
  .supporting-data-title{
    font-family: 'montserrat-bold';
    font-size: 3rem;
  }
  .supporting-data-image{
    width: 15rem;
    max-width: 100%;
  }
}
@media only screen and (min-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-left: .5rem;
  }
  .article-second{
    padding: 0 .5rem;
  }
  .supporting-data-title{
    font-family: 'montserrat-bold';
    font-size: 4rem;
  }
  .supporting-data-image{
    height: 19rem;
    max-width: 100%;
  }
}

.supporting-title{
  font-family: 'montserrat-bold';
  font-size: 2rem;
}
.supporting-detail{
  font-family: 'montserrat-medium';
  font-size: 1.3rem;
}

.supporting-data-detail{
  font-family: 'montserrat-bold';
  font-size: 2rem;
}
.supporting_hr{
  width: 1px;
  border: 1px solid $yellow1;
}
.home-article-title1-enter-active, .home-article-title1-leave-active{
  transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
  transform: translateY(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
  transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
  transform: translateY(-10rem);
  opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
  transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
  transform: translateX(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
</style>

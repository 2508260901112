<template>
  <div class="px-5 py-5 d-inline-block w-100">
    <div class="container">
      <p class="m-0 text-primary text-center competition-title">{{ $t("our_competition") }}</p>
      <!-- <p class="m-0 competition-detail my-3 text-center">The Student Open is a multi-sport event consisting of football, Futsal, Basketball, Tennis, Softball, Badminton, and Taekwondo with students representing their schools and sports clubs participating in a league style. Students who compete will become ambassadors for their school or club for 3 months of matches held every weekend.</p> -->
			<p class="m-0 competition-detail my-3 text-center">The Student Open is a multi-sport event consisting of football, Futsal, Basketball, Softball, Volley, Badminton, Tennis, Table Tennis, Swimming, Archery and Taekwondo with students representing their schools and sports clubs participating Students who compete will become ambassadors for their.</p>

			<div>
				<div v-if="base.mode == 'new'">
					<ul class="nav nav-pills nav-fill" id="our-competition-tab" role="tablist">
						<li class="nav-item" role="presentation">
							<button class="nav-link active w-100" id="pills-sport-tab" data-toggle="pill" data-target="#pills-sport" type="button" role="tab" aria-controls="pills-sport" aria-selected="true">Sport</button>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link w-100" id="pills-academic-tab" data-toggle="pill" data-target="#pills-academic" type="button" role="tab" aria-controls="pills-academic" aria-selected="false">Academic</button>
						</li>
					</ul>
					<div class="tab-content mt-3" id="pills-tabContent">
						<div class="tab-pane fade show active" id="pills-sport" role="tabpanel" aria-labelledby="pills-sport-tab">
							<div class="row justify-content-center">
								<div class="col-12 col-md-4 col-lg-3 mb-3" v-for="(competition, index) in arr_competition" :key="index">
									<div class="card shadow-sm" style="border: none;">
										<div class="card-body position-relative">
											<div class="position-absolute px-3 py-1 competition-status" :class="'competition-style-' + competition.status_data">{{ competition.status }}</div>
											<div class="text-center w-100">
												<img :src="competition.category_sport != null ? competition.category_sport.image : '@/assets/no_image.png'" class="" style="height: 10rem; max-width: 100%;" :alt="'category sport image ' + (index + 1)">
											</div>

											<div class="mt-3">
												<p class="mb-1 text-primary our-competition-name text-center">{{ competition.name }}</p>
												<div class="text-center">
													<button class="btn btn-primary our-competition-register text-warning" @click="onRegister(index)" :disabled="competition.status_data != 'open'" style="width: 10rem; max-width: 100%;">{{ $t('register') }}</button>
												</div>
												<div class="text-center mt-3">
													<a class="text-primary our-competition-more-info" href="#!" style="cursor: pointer;" @click="onCompetitionClicked(index)">{{ $t('more_info') }}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane fade" id="pills-academic" role="tabpanel" aria-labelledby="pills-academic-tab">
							<div class="row justify-content-center">
								<div class="col-12 col-md-4 col-lg-3 mb-3" v-for="(competition, index) in arr_academic_competition" :key="index">
									<div class="card shadow-sm" style="border: none;">
										<div class="card-body position-relative">
											<div class="position-absolute px-3 py-1 competition-status" :class="'competition-style-' + competition.status_data">{{ competition.status }}</div>
											<div class="text-center w-100">
												<img :src="competition.category_sport != null ? competition.category_sport.image : '@/assets/no_image.png'" class="" style="height: 10rem; max-width: 100%;" :alt="'category sport image ' + (index + 1)">
											</div>

											<div class="mt-3">
												<p class="mb-1 text-primary our-competition-name text-center">{{ competition.name }}</p>
												<div class="text-center">
													<button class="btn btn-primary our-competition-register text-warning" @click="onRegisterAcademic(index)" :disabled="competition.status_data != 'open'" style="width: 10rem; max-width: 100%;">{{ $t('register') }}</button>
												</div>
												<div class="text-center mt-3">
													<a class="text-primary our-competition-more-info" href="#!" style="cursor: pointer;" @click="onAcademicCompetitionClicked(index)">{{ $t('more_info') }}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row justify-content-center" v-else-if="base.mode == 'old'">
					<div class="col-12 col-md-4 col-lg-3 mb-3" v-for="(competition, index) in arr_competition" :key="index">
						<div class="card shadow-sm" style="border: none;">
							<div class="card-body position-relative">
								<div class="position-absolute px-3 py-1 competition-status" :class="'competition-style-' + competition.status_data">{{ competition.status }}</div>
								<div class="text-center w-100">
									<img :src="competition.category_sport != null ? competition.category_sport.image : '@/assets/no_image.png'" class="" style="height: 10rem; max-width: 100%;" :alt="'category sport image ' + (index + 1)">
								</div>

								<div class="mt-3">
									<p class="mb-1 text-primary our-competition-name text-center">{{ competition.name }}</p>
									<div class="text-center">
										<button class="btn btn-primary our-competition-register text-warning" @click="onRegister(index)" :disabled="competition.status_data != 'open'" style="width: 10rem; max-width: 100%;">{{ $t('register') }}</button>
									</div>
									<div class="text-center mt-3">
										<a class="text-primary our-competition-more-info" href="#!" style="cursor: pointer;" @click="onCompetitionClicked(index)">{{ $t('more_info') }}</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

import Football from '@/assets/football.png';
import Futsal from '@/assets/futsal.png';
import Basket from '@/assets/basket.png';
import Softball from '@/assets/softball.png';
import Badminton from '@/assets/badminton.png';
import Tennis from '@/assets/tennis.png';
import Taekwondo from '@/assets/taekwondo.png';

export default {
  components: {
  },
  props: ['articleTitle1Flag', 'articleTitle2Flag', ],
  data(){
    return{
      base: null,
      arr_competition: [
        {
          id: "1",
          image: Football,
          status: 'Open',
          status_data: 'open',
          date: momentTZ(),
          name: "Football",
        },
        {
          id: "1",
          image: Futsal,
          status: 'Open',
          status_data: 'open',
          date: momentTZ(),
          name: "Futsal",
        },
        {
          id: "1",
          image: Basket,
          status: 'Open',
          status_data: 'open',
          date: momentTZ(),
          name: "Basket",
        },
        {
          id: "1",
          image: Softball,
          status: 'Open',
          status_data: 'open',
          date: momentTZ(),
          name: "Softball",
        },
        {
          id: "1",
          image: Badminton,
          status: 'Open',
          status_data: 'open',
          date: momentTZ(),
          name: "Badminton",
        },
        {
          id: "1",
          image: Tennis,
          status: 'Open',
          status_data: 'closed',
          date: momentTZ(),
          name: "Tennis",
        },
        {
          id: "1",
          image: Taekwondo,
          status: 'Soon',
          status_data: 'soon',
          date: momentTZ(),
          name: "Taekwondo",
        },
      ],
			arr_academic_competition: [],
    }
  },
  watch: {
    isLoading(val){
      this.$emit("onLoading", val, 2)
    },
  },
  created(){
    this.base = new Base()

    this.get_event_category_sport()
		this.get_academic_event_category_sport()
  },
  methods:{
    async get_event_category_sport(){
      var response = await this.base.request(this.base.url_api + "/event/category-sport/all?type=sport&is_publish=1&is_show=1&rel_type=simple")
      this.$emit('onCompleteRequest', 1)

      if(response != null){
        if(response.status === "success"){
          for(let event_category_sport of response.data){
            if(event_category_sport.category_sport != null)
              event_category_sport.category_sport.image = this.base.host + "/media/category-sport?file_name=" + event_category_sport.category_sport.file_name
            event_category_sport.event.start_school_registration_moment = momentTZ.tz(event_category_sport.event.start_school_registration, this.base.locale_timezone)
            event_category_sport.event.end_school_registration_moment = momentTZ.tz(event_category_sport.event.end_school_registration, this.base.locale_timezone)

            // var total_team = 0
            // var total_registration = 0
            // for(let x in event_category_sport.category){
            //   total_team += event_category_sport.category[x].max_total_team_per_school + event_category_sport.category[x].max_total_team_per_club
            //   total_registration += event_category_sport.category[x].register.length;
            // }
            event_category_sport.status = momentTZ().isBefore(event_category_sport.event.start_school_registration_moment) ? 'Soon' : (momentTZ().isAfter(event_category_sport.event.end_school_registration_moment) || event_category_sport.total_registered >= event_category_sport.total_max_registration || event_category_sport.is_match_generated ? 'Closed' : 'Open')
            event_category_sport.status_data = event_category_sport.status.toLowerCase()
            if(event_category_sport.category_sport != null)
              event_category_sport.name = event_category_sport.category_sport.name
          }
          this.arr_competition = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
		async get_academic_event_category_sport(){
			var response = await this.base.request(this.base.url_api + "/event/category-sport/all?type=academic&is_publish=1&is_show=1&rel_type=simple")
			this.$emit('onCompleteRequest', 1)

			if(response != null){
				if(response.status === "success"){
					for(let event_category_sport of response.data){
						if(event_category_sport.category_sport != null)
							event_category_sport.category_sport.image = this.base.host + "/media/category-sport?file_name=" + event_category_sport.category_sport.file_name
						event_category_sport.event.start_school_registration_moment = momentTZ.tz(event_category_sport.event.start_school_registration, this.base.locale_timezone)
						event_category_sport.event.end_school_registration_moment = momentTZ.tz(event_category_sport.event.end_school_registration, this.base.locale_timezone)

						// var total_team = 0
						// var total_registration = 0
						// for(let x in event_category_sport.category){
						//   total_team += event_category_sport.category[x].max_total_team_per_school + event_category_sport.category[x].max_total_team_per_club
						//   total_registration += event_category_sport.category[x].register.length;
						// }
						event_category_sport.status = momentTZ().isBefore(event_category_sport.event.start_school_registration_moment) ? 'Soon' : (momentTZ().isAfter(event_category_sport.event.end_school_registration_moment) || event_category_sport.total_registered >= event_category_sport.total_max_registration || event_category_sport.is_match_generated ? 'Closed' : 'Open')
						event_category_sport.status_data = event_category_sport.status.toLowerCase()
						if(event_category_sport.category_sport != null)
							event_category_sport.name = event_category_sport.category_sport.name
					}
					this.arr_academic_competition = response.data
				}
				else
					this.base.show_error(response.message)
			}
			else
				console.log(this.$t('server_error'))
		},
    async onRegister(index){
      var token = await window.localStorage.getItem('token')
      var competition = this.arr_competition[index]
      window.localStorage.setItem('competition_data', JSON.stringify(competition))

      if(token == null)
        window.$('#login-modal').modal('show')
      else
        window.location.href = this.base.admin_host + '?competition_id=' + competition.id
    },
		async onRegisterAcademic(index){
			var token = await window.localStorage.getItem('token')
			var competition = this.arr_academic_competition[index]
			window.localStorage.setItem('competition_data', JSON.stringify(competition))

			if(token == null)
				window.$('#login-modal').modal('show')
			else
				window.location.href = this.base.admin_host + '?competition_id=' + competition.id
		},
    onCompetitionClicked(index){
      var data = this.arr_competition[index]
      window.location.href = '/competition/information?type=sport&id=' + data.id
    },
		onAcademicCompetitionClicked(index){
			var data = this.arr_academic_competition[index]
			window.location.href = '/competition/information?type=academic&id=' + data.id
		},
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-right: .5rem;
  }
  .article-second{
    padding-left: .5rem;
  }
}
@media only screen and (min-width: 720px) {
  .article-first{
    padding-right: .5rem;
  }
  .article-last{
    padding-left: .5rem;
  }
  .article-second{
    padding: 0 .5rem;
  }
}

#our-competition-tab .nav-link.active, .nav-pills .show > .nav-link{
	background-color: $yellow1;
	color: $green1 !important;
}
#our-competition-tab .nav-link{
	color: $gray-900 !important;
}
.our-competition-name{
  font-family: 'montserrat-bold';
}
.our-competition-register{
  font-family: 'montserrat-medium';
}
.our-competition-more-info{
  font-family: 'montserrat-regular';
}
.competition-title{
  font-family: 'montserrat-bold';
  font-size: 2rem;
}
.competition-detail{
  font-family: 'montserrat-light';
}
.competition-status{
  right: 1rem;
  top: 1rem;
  font-family: 'montserrat-bold';
  border-radius: .3rem;
}
.competition-style-open{
  background-color: $green2;
  color: $green1;
}
.competition-style-closed{
  background-color: $red2;
  color: $red1;
}
.competition-style-soon{
  background-color: $yellow2;
  color: $yellow1;
}
.home-article-title1-enter-active, .home-article-title1-leave-active{
  transition: all 2s;
}
.home-article-title1-leave-to, .home-article-title1-enter {
  transform: translateY(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
.home-article-title2-enter-active, .home-article-title2-leave-active{
  transition: all 2s;
}
.home-article-title2-leave-to, .home-article-title2-enter {
  transform: translateY(-10rem);
  opacity: 0;
}
.home-article-content-enter-active, .home-article-content-leave-active{
  transition: all 2s;
}
.home-article-content-leave-to, .home-article-content-enter {
  transform: translateX(-10rem);
  // margin-bottom: 5rem !important;
  opacity: 0;
}
</style>

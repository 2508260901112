<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail :title="competition_data.category_sport != null && competition_data.category_sport.name != null ? competition_data.category_sport.name : ''" />
    </div>
    <CompetitionTab :competition_data="competition_data"/>

    <div class="my-5 pb-5 container" v-show="page_type == ''">
      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t('schedule_event') }}</p>

        <!-- <div class="d-flex justify-content-between mt-3 competition-detail-event">
          <p class="m-0 py-2 px-2">{{ $t('school_registration') }}</p>
          <p class="m-0 py-2 px-2">{{ competition_data.event != null && competition_data.event.start_school_registration_moment != null ? competition_data.event.start_school_registration_moment.format('DD/MM/YYYY') : '-' }} - {{ competition_data.event != null && competition_data.event.end_school_registration_moment != null ? competition_data.event.end_school_registration_moment.format('DD/MM/YYYY') : '-' }}</p>
        </div>
        <div class="d-flex justify-content-between competition-detail-event">
          <p class="m-0 py-2 px-2">{{ $t('registration_team_player') }}</p>
          <p class="m-0 py-2 px-2">{{ competition_data.event != null && competition_data.event.start_registration_team_moment != null ? competition_data.event.start_registration_team_moment.format('DD/MM/YYYY') : '-' }} - {{ competition_data.event != null && competition_data.event.end_registration_team_moment != null ? competition_data.event.end_registration_team_moment.format('DD/MM/YYYY') : '-' }}</p>
        </div>
        <div class="d-flex justify-content-between competition-detail-event">
          <p class="m-0 py-2 px-2">{{ $t('payment_registration') }}</p>
          <p class="m-0 py-2 px-2">{{ competition_data.event != null && competition_data.event.start_payment_registration_moment != null ? competition_data.event.start_payment_registration_moment.format('DD/MM/YYYY') : '-' }} - {{ competition_data.event != null && competition_data.event.end_payment_registration_moment != null ? competition_data.event.end_payment_registration_moment.format('DD/MM/YYYY') : '-' }}</p>
        </div>
        <div class="d-flex justify-content-between competition-detail-event">
          <p class="m-0 py-2 px-2">{{ $t('coach_meeting') }}</p>
          <p class="m-0 py-2 px-2">{{ competition_data.event != null && competition_data.event.coach_meeting_moment != null ? competition_data.event.coach_meeting_moment.format('DD/MM/YYYY') : '-' }}</p>
        </div> -->
        <div class="d-flex justify-content-between competition-detail-event">
          <p class="m-0 py-2 px-2">{{ $t('competition_date') }}</p>
          <p class="m-0 py-2 px-2">{{ competition_data.start_date_moment != null ? competition_data.start_date_moment.format('DD/MM/YYYY') : '-' }} - {{ competition_data.end_date_moment != null ? competition_data.end_date_moment.format('DD/MM/YYYY') : '-' }}</p>
        </div>
        <!-- <div class="d-flex justify-content-between competition-detail-event">
          <p class="m-0 py-2 px-2">{{ $t('medal_ceremony') }}</p>
          <p class="m-0 py-2 px-2">{{ competition_data.event != null && competition_data.event.medal_ceremony_moment != null ? competition_data.event.medal_ceremony_moment.format('DD/MM/YYYY') : '-' }}</p>
        </div> -->
      </div>

      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t('category') }}</p>
        <table class="table table-member table-striped d-block d-md-table">
          <thead>
            <tr>
              <th v-for="(tab, index) in arr_tab" :key="index" class="competition-detail-category-th">{{ tab.name }}</th>
            </tr>
          </thead>
          <tbody v-if="competition_data.category != null">
            <tr v-for="(category, index) in competition_data.category" :key="index">
              <td v-for="(tab, index1) in arr_tab" :key="index1" class="competition-detail-category-td" style="vertical-align: middle;">
								<div v-if="tab.id == 'action'">
									<button class="rounded-circle btn btn-white" style="aspect-ratio: 1;" @click="onRegistrationClicked(index)">
										<font-awesome-icon :icon="['fas', 'eye']" class="text-primary"/>
									</button>
								</div>

								<div :class="{'competition-detail-category-td-wrap': index1 == 1, 'text-center': index1 != 1}" v-else>
									{{ category[tab.id] }}
								</div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center competition-detail-category-td" :colspan="arr_tab.length">{{ $t('no_data_found') }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t('payment_method') }}</p>
        <div class="mt-3">
          <div v-if="arr_payment_method.length > 0">
            <div v-for="(payment_method, index) in arr_payment_method" :key="index" class="mb-3">
              <p class="m-0 competition-detail-category-th">{{ payment_method.title }}</p>
              <p class="m-0 competition-detail-category-td text-justify" v-html="payment_method.content"></p>
            </div>
          </div>
          <div v-else class="d-flex align-items-center justify-content-center">
            <p class="m-0 competition-detail-category-td">{{ $t('no_data_found') }}</p>
          </div>
        </div>
      </div>

      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t('competition_guide') }}</p>
        <div class="mt-3">
          <div v-if="arr_payment_method.length > 0">
            <div v-for="(competition_guide, index) in arr_competition_guide" :key="index" class="competition-guide-item-grid" style="gap: 1rem">
              <img :src="competition_guide.url_image" :alt="'competition guide image ' + (index + 1)" style="width: 5rem; height: 5rem;">
              <div class="d-flex flex-column justify-content-center flex-grow-1">
                <p class="m-0 competition-detail-category-th custom">{{ competition_guide.title }}</p>
                <!-- <p class="m-0 competition-detail-category-td" v-html="competition_guide.content"></p> -->
                <p class="m-0 competition-detail-category-td custom" v-html="competition_guide.content"></p>
              </div>
              <div class="d-flex align-items-center">
                <a :href="competition_guide.url_document" target="_blank" style="height: 2rem;">
                  <img :src=icon_download alt="Download" style="width: 2rem;"/>
                </a>
              </div>
            </div>
          </div>
          <div v-else class="d-flex align-items-center justify-content-center">
            <p class="m-0 competition-detail-category-td">{{ $t('no_data_found') }}</p>
          </div>
        </div>
      </div>
    </div>

		<ListRegistration v-show="page_type == 'list_registration'"
			:event_category_sport_category="event_category_sport_category_data"
			:competition_data="competition_data"
			@onBackClicked="onBackClicked"/>

  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'
import ListRegistration from '@/pages/competition/list_registration.vue'

import Icon_Download from '@/assets/download.png'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
    'CompetitionTab': CompetitionTab,
		'ListRegistration': ListRegistration,
  },
  data() {
    return {
      base: null,
      scrollY: 0,
      icon_download: Icon_Download,
      arr_factor: [false, false, false,],
      flag: {
        aboutUsTitle1Flag: false,
        aboutUsImage1Flag: false,
        aboutUsContent1Flag: false,
        aboutUsTitle2Flag: false,
        aboutUsImage2Flag: false,
        aboutUsContent2Flag: false,
        aboutUsTitle3Flag: false,
        aboutUsImage3Flag: false,
        aboutUsContent3Flag: false,
      },
      competition_data: {},
      arr_tab: [
				{
					id: 'action',
					name_id: 'action',
				},
        {
          id: 'name',
          name_id: 'category',
        },
        {
          id: 'requirement',
          name_id: 'requirement',
        },
        {
          id: 'player_format',
          name_id: 'player',
        },
        {
          id: 'fee_registration_team_format',
          name_id: 'fee_registration_team',
        },
        {
          id: 'fee_per_match_format',
          name_id: 'fee_per_match',
        },
        {
          id: 'total_team_school_format',
          name_id: 'total_team_school',
        },
        {
          id: 'total_team_club_format',
          name_id: 'total_team_club',
        },
				{
					id: 'total_team_personal_format',
					name_id: 'total_team_personal',
				},
      ],
			page_type: '',
      arr_payment_method: [],
      arr_competition_guide: [],
			event_category_sport_category_data: {},
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      this.manage_start_animation()
    },
    scrollY() {
      this.manage_start_animation()
    },
  },
  created() {
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1
    // this.arr_factor = [true, true, true,]

    for(let tab of this.arr_tab)
      tab.name = this.$t(tab.name_id)

    this.get_competition_data()
    this.get_payment_method_data()
    this.get_competition_guide_data()
  },
  methods: {
		onBackClicked(){
			this.page_type = ''
			this.event_category_sport_category_data = {}
		},
    onImageLoad(index) {
      var team = this.arr_team[index]
      team.is_image_loaded = true
      this.$set(this.arr_team, index, team)
    },
    manage_start_animation() {
      var context = this
      this.flag.aboutUsTitle1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsTitle1Flag, this.arr_factor, 0)
      this.flag.aboutUsImage1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsImage1Flag, this.arr_factor, 0)
      this.flag.aboutUsContent1Flag = this.base.check_start_animation(this.scrollY, this.flag.aboutUsContent1Flag, this.arr_factor, 0)

      setTimeout(() => {
        if (context.flag.aboutUsContent1Flag) {
          var margin = window.$('#about-us-content').height()
          context.flag.aboutUsTitle2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle2Flag, context.arr_factor, 0, margin - margin)
          context.flag.aboutUsContent2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent2Flag, context.arr_factor, 0, margin - 300)

          if (context.flag.aboutUsContent2Flag) {
            context.flag.aboutUsImage2Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage2Flag, context.arr_factor, 0, margin + window.$('#trust-content').innerHeight() - 300)

            if (context.flag.aboutUsImage2Flag) {
              margin += window.$('#trust-container').innerHeight() - 950
              context.flag.aboutUsTitle3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsTitle3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsImage3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsImage3Flag, context.arr_factor, 800, margin)
              context.flag.aboutUsContent3Flag = context.base.check_start_animation(context.scrollY, context.flag.aboutUsContent3Flag, context.arr_factor, 800, margin)
            }
          }
        }
      }, 200)

    },
    handleScroll() {
      this.scrollY = window.scrollY
    },
    async get_competition_data(){
      var response = await this.base.request(this.base.url_api + "/event/category-sport?id=" + this.$route.query.id)
      this.$set(this.arr_factor, 0, true)

      if(response != null){
        if(response.status === "success"){
          response.data.start_date_moment = momentTZ.tz(response.data.start_date, this.base.locale_timezone)
          response.data.end_date_moment = momentTZ.tz(response.data.end_date, this.base.locale_timezone)
          response.data.event.start_school_registration_moment = momentTZ.tz(response.data.event.start_school_registration, this.base.locale_timezone)
          response.data.event.end_school_registration_moment = momentTZ.tz(response.data.event.end_school_registration, this.base.locale_timezone)
          response.data.event.start_registration_team_moment = momentTZ.tz(response.data.event.start_registration_team, this.base.locale_timezone)
          response.data.event.end_registration_team_moment = momentTZ.tz(response.data.event.end_registration_team, this.base.locale_timezone)
          response.data.event.start_payment_registration_moment = momentTZ.tz(response.data.event.start_payment_registration, this.base.locale_timezone)
          response.data.event.end_payment_registration_moment = momentTZ.tz(response.data.event.end_payment_registration, this.base.locale_timezone)
          response.data.event.coach_meeting_moment = momentTZ.tz(response.data.event.coach_meeting, this.base.locale_timezone)
          response.data.event.medal_ceremony_moment = momentTZ.tz(response.data.event.medal_ceremony, this.base.locale_timezone)

          for(let category of response.data.category){
            category.player_format = category.min_player.toLocaleString(this.base.locale_string) + " - " + category.max_player.toLocaleString(this.base.locale_string)
            category.fee_registration_team_format = category.fee_registration > 0 ? "Rp. " + category.fee_registration.toLocaleString(this.base.locale_string) : 'Free'
            category.fee_per_match_format = category.fee_per_match > 0 ? "Rp. " + category.fee_per_match.toLocaleString(this.base.locale_string) : 'Free'
            category.total_team_school_format = category.total_school_registration_not_canceled.toLocaleString(this.base.locale_string) + ' of ' + category.max_total_team_per_school.toLocaleString(this.base.locale_string)
            category.total_team_club_format = category.total_club_registration_not_canceled.toLocaleString(this.base.locale_string) + ' of ' + category.max_total_team_per_club.toLocaleString(this.base.locale_string)
						category.total_team_personal_format = category.total_personal_registration_not_canceled.toLocaleString(this.base.locale_string) + ' of ' + category.max_total_team_per_club.toLocaleString(this.base.locale_string)
          }

          this.competition_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_payment_method_data(){
      var response = await this.base.request(this.base.url_api + "/how-payment/all")
      this.$set(this.arr_factor, 1, true)

      if(response != null){
        if(response.status === "success"){
          this.arr_payment_method = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_competition_guide_data(){
      var response = await this.base.request(this.base.url_api + "/competition-guide/all")
      this.$set(this.arr_factor, 2, true)

      if(response != null){
        if(response.status === "success"){
          for(let competition_guide of response.data){
            competition_guide.url_image = this.base.host + '/media/competition-guide?file_name=' + competition_guide.file_name
            competition_guide.url_document = this.base.host + '/media/competition-guide/document?file_name=' + competition_guide.document_file_name + '&mime_type=' + competition_guide.document_mime_type
          }
          this.arr_competition_guide = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
		onRegistrationClicked(index){
			this.event_category_sport_category_data = JSON.parse(JSON.stringify(this.competition_data.category[index]))
			this.page_type = 'list_registration'
		},
  }
}
</script>

<style lang="scss">
.competition-detail-event{
  border-bottom: 1px solid black;
}
.competition-detail-event p{
  font-family: 'montserrat-regular';
  font-size: 1rem;
  color: $gray20;
}
.competition-detail-category-th{
  font-family: 'montserrat-medium';
  color: $primary;
}
.competition-detail-category-th.custom{
  font-size: 1.2rem;
  font-weight: 600;
}
.competition-detail-category-td{
  font-family: 'montserrat-regular';
  color: $gray20;
}
.competition-detail-category-td-wrap{
  min-width: 250px;
  text-wrap: balance;
}

.competition-detail-category-td.custom{
  font-weight: 600;
}

.competition-detail-title{
  font-family: 'montserrat-bold';
  font-size: 2rem;
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

.competition-guide-item-grid{
  display: grid;
  grid-template-columns: 5rem 1fr auto;
  gap: 1rem;
  margin-bottom: 1rem;
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>

<template>
  <div>
    <label class="m-0" v-if="title != null">{{ title }}</label>
    <label class="m-0 mb-3" v-show="with_info == null || (with_info != null && with_info)">Image size must not exceed {{ max_size.toLocaleString(base.locale_string) }}MB</label>

    <div v-if="image_data != null && image_data.image_display != null" class="position-relative">
			<font-awesome-icon :icon="['fas', 'circle-xmark']" class="text-primary position-absolute" style="cursor: pointer; left: 0; top: 0;" @click="onRemoveClicked" v-show="with_remove == null || (with_remove != null && with_remove)"/>
      <img class="rounded mr-2 mb-3" style="width: 10rem;" :src="image_data.image_display" data-holder-rendered="true" alt="image preview"/>
    </div>

    <div>
      <input type="file" class="d-none" accept="image/*" ref="input_image" @change="change_image($event)" />
      <button class="btn btn-primary" @click="choose_image" style="font-size: .8rem;">Choose Image</button>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Base from '@/utils/base';

export default {
  props: ['title', 'image_data1', 'with_info', 'with_remove', ],
  data(){
    return{
      base: null,
      image_data: {},
      max_size: 2,
    }
  },
  watch: {
    image_data(val){
      if(val != null && val.data_image != null)
        this.$emit('onResponse', val)
    },
    image_data1(val){
      this.image_data = val
    },
  },
  created(){
    this.base = new Base()

		if(this.image_data1 != null)
			this.image_data = this.image_data1
  },
  methods: {
    choose_image() {
      $(this.$refs['input_image']).trigger('click')
    },
    async change_image(event) {
      if(event.target.files[0].size / 1024 / 1024 > this.max_size)
        this.base.show_error('Your File exceed '+this.max_size+" MB")
      else{
        const image = new Image()
        image.src = URL.createObjectURL(event.target.files[0])

        var context = this
        this.base.toDataURL(URL.createObjectURL(event.target.files[0]), (response) => {
          context.image_data = {
            image_display: URL.createObjectURL(event.target.files[0]),
            data_image: response,
          }
        })
      }
    },
		onRemoveClicked(){
			this.$emit('onRemoveClicked')
		},
  }
}
</script>

<style lang="scss">
.custom-loading-container{
  background-color: $white;
  top: 0;
}
.custom-loading-enter-active, .custom-loading-leave-active{
  transition: all 2s;
}
.custom-loading-leave-to, .custom-loading-enter {
  opacity: 0;
}
</style>
<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail
        :title="competition_data.category_sport != null && competition_data.category_sport.name != null ? competition_data.category_sport.name : ''" />
    </div>
    <CompetitionTab :competition_data="competition_data" />

    <div class="my-5 pb-5 container" v-show="!isDetailFlag">
      <div class="py-3">
        <p class="m-0 competition-detail-title">{{ $t('match') }}</p>
        <div class="py-3">
          <div class="form-group ">
            <date-picker v-model="date" type="date" input-class="form-control" format="DD/MM/YYYY" value-type="format"
              :disabled-date="disabledDate" />
          </div>
          <div>
            <div v-for="(match, index) in arr_match" :key="index" class="border-bottom py-4 selector-hover"
              >
              <!-- <a class="" data-toggle="collapse" :href="'#action' + index" aria-expanded="false" :aria-controls="'#action'+index">
                <font-awesome-icon icon="fa-solid fa-ellipsis-h" class="" size="md" />
              </a> -->
              <div class="cursor-pointer" @click="onSelectedMatch(index)">
                <div class="match-grid">
									<div class="d-flex align-items-center">
										<div>
											<p class="m-0 font-weight-bold">
                        {{ match.name }}
                        <span class="badge badge-primary">
                          {{ match.status_format }}
                        </span>
                      </p>
											<p class="m-0 text-secondary" v-if="match.group != null">{{ match.group.event_category_sport_category.name }} / {{
												match.group.name }}</p>
											<p class="m-0 text-secondary" v-else-if="match.cutoff_group != null">{{ match.cutoff_group.event_category_sport_category.name }} / Round {{ match.cutoff_group.cutoff_category.name }}</p>
										</div>
									</div>

									<div class="d-flex flex-column align-items-center">
										<p class="m-0 font-weight-bold">{{ match.date.format('DD/MM/YYYY') }}</p>
										<p class="m-0 text-secondary">{{ match.date.format('HH:mm') }}</p>
									</div>

									<div class="text-right">
										<p class="m-0 font-weight-bold">
											{{ match.event_category_sport_venue != null ? match.event_category_sport_venue.venue.name : 'No Venue' }}
										</p>
										<p class="m-0 text-secondary">
											{{ match.event_category_sport_venue != null ? match.event_category_sport_venue.court : 'No Court' }}
										</p>
									</div>
								</div>

								<div v-if="match.cutoff_group != null">
									<div class="row">
										<div class="col-6 mb-3" v-for="(member, index1) in match.cutoff_group.member" :key="index1">
											<div class="d-flex align-items-center justify-content-center justify-content-md-start flex-column flex-md-row member-container">
												<div class="d-flex align-items-center justify-content-center match-image-container">
													<img :src="member.registration_event.url_image" :alt="'member image ' + (index1 + 1)"/>
												</div>
												<div class="text-center text-md-left mt-2 mt-md-0 ml-md-3">
													<p class="m-0 font-weight-bold">{{ member.registration_event.user.name }}</p>
													<p class="m-0">{{ member.registration_event.team_name }}</p>
													<p class="m-0 text-secondary">{{ member.registration_event.jersey_team }}</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div v-else class="d-flex align-items-center justify-content-between">
									<div class="d-flex align-items-center justify-content-center justify-content-md-start flex-column flex-md-row member-container">
										<div class="d-flex align-items-center justify-content-center match-image-container">
											<img :src="match.member1.registration_event.url_image" :alt="'registration image ' + (index + 1)"/>
										</div>
										<div class="text-center text-md-left mt-2 mt-md-0 ml-md-3">
											<div class="d-flex">
												<p class="m-0 font-weight-bold">{{ match.member1.registration_event.user.name }}</p>
												<span class="badge badge-danger ml-1 mt-1" v-show="match.group_member1_disqualified == 1">Disqualified</span>
											</div>
											<p class="m-0">{{ match.member1.registration_event.team_name }}</p>
											<p class="m-0 text-secondary">{{ match.member1.registration_event.jersey_team }}</p>
										</div>
									</div>

									<div class="d-flex align-items-center justify-content-center score-container">
										<h4 class="font-weight-bold">{{ match.group_member1_score }}</h4>
										<h4 class="mx-3">-</h4>
										<h4 class="font-weight-bold">{{ match.group_member2_score }}</h4>
									</div>

									<div
										class="d-flex align-items-center justify-content-end flex-column-reverse flex-md-row member-container">
										<div class="text-center text-md-right mt-2 mt-md-0 mr-md-3">
											<div class="d-flex justify-content-end">
												<span class="badge badge-danger mr-1 mt-1" v-show="match.group_member2_disqualified == 1">Disqualified</span>
												<p class="m-0 font-weight-bold">{{ match.member2.registration_event.user.name }}</p>

											</div>
											<p class="m-0">{{ match.member2.registration_event.team_name }}</p>
											<p class="m-0 text-secondary">{{ match.member2.registration_event.jersey_team }}</p>
										</div>
										<div class="d-flex align-items-center justify-content-center match-image-container">
											<img :src="match.member2.registration_event.url_image" :alt="'opp registration image ' + (index + 1)"/>
										</div>
									</div>
								</div>

              </div>
              <button class="btn btn-primary btn-sm w-100 mt-3" v-show="user != null && user.is_coordinator" @click="onEdit(index)" style="" >
                Edit
              </button>
              <!-- <div class="collapse" :id="'action'+index">
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <MatchDetail :match_data="selected_match" :user_data="user" v-show="isDetailFlag" @onBackClicked="onBackDetailMatch"
      @onForceReload="onForceReload" />
    <ModalMatch v-if="user != null && user.is_coordinator" :match="selected_match" :data="competition_data" :onShow="is_show_modal" @onForceReload="onForceReload"/>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'
import MatchDetail from '@/pages/competition/match_detail.vue'
import ModalMatch from "@/pages/competition/modal_match";

import NoImage from '@/assets/no_image.png'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
    'CompetitionTab': CompetitionTab,
    'MatchDetail': MatchDetail,
    'ModalMatch': ModalMatch,
    DatePicker,
  },
  data() {
    return {
      base: null,
      arr_factor: [false,],
      date: '',
      arr_match: {},
      competition_data: {},
      selected_match: {},
      selected_index: -1,
      isDetailFlag: false,
      is_show_modal: false,
      user: {},
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    date() {
      this.get_data()
    },
  },
  async mounted() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    // this.arr_factor = [true,]
    var user = await window.localStorage.getItem('user')
    this.user = JSON.parse(user)

		var token = await window.localStorage.getItem('token')
		if(token != null)
			this.get_user_data()
    this.get_competition_data()
    this.get_data()
  },
  methods: {
		async get_user_data() {
			var response = await this.base.request(this.base.url_api + "/auth/profile?event_category_sport_id=" + this.$route.query.id);

			if (response != null) {
				if (response.status === "success") {
					this.user = response.data;
				} else this.base.show_error(response.message);
			} else console.log(this.$t("server_error"));
		},
    onEdit(index){
      this.selected_match = this.arr_match[index]
      this.selected_index = index
      this.is_show_modal = true
      window.$('#editMatch').modal('show')
    },
    onSelectedMatch(index) {
      // if(this.user != null && this.user.id != null){
        this.selected_match = this.arr_match[index]
        this.selected_index = index
        this.isDetailFlag = true
      // }
    },
    onBackDetailMatch(isFinished) {
      this.isDetailFlag = false

			if(isFinished)
				this.get_data()
			else
				this.get_one_data()
    },
    onForceReload() {
      this.get_data()
    },
    disabledDate(date) {
      return this.competition_data.id != null && (momentTZ(date).isBefore(this.competition_data.start_date_moment) || momentTZ(date).isAfter(this.competition_data.end_date_moment))
    },
    async get_competition_data() {
      var response = await this.base.request(this.base.url_api + "/event/category-sport?id=" + this.$route.query.id)

      if (response != null) {
        if (response.status === "success") {
          response.data.start_date_moment = momentTZ.tz(response.data.start_date, this.base.locale_timezone)
          response.data.end_date_moment = momentTZ.tz(response.data.end_date, this.base.locale_timezone)
          response.data.event.start_school_registration_moment = momentTZ.tz(response.data.event.start_school_registration, this.base.locale_timezone)
          response.data.event.end_school_registration_moment = momentTZ.tz(response.data.event.end_school_registration, this.base.locale_timezone)
          response.data.event.start_registration_team_moment = momentTZ.tz(response.data.event.start_registration_team, this.base.locale_timezone)
          response.data.event.end_registration_team_moment = momentTZ.tz(response.data.event.end_registration_team, this.base.locale_timezone)
          response.data.event.start_payment_registration_moment = momentTZ.tz(response.data.event.start_payment_registration, this.base.locale_timezone)
          response.data.event.end_payment_registration_moment = momentTZ.tz(response.data.event.end_payment_registration, this.base.locale_timezone)
          response.data.event.coach_meeting_moment = momentTZ.tz(response.data.event.coach_meeting, this.base.locale_timezone)
          response.data.event.medal_ceremony_moment = momentTZ.tz(response.data.event.medal_ceremony, this.base.locale_timezone)

          this.competition_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_one_data() {
      var response = await this.base.request(this.base.url_api + '/match/all?id=' + this.arr_match[this.selected_index].id + '&rel_type=simple')

      if (response != null) {
        if (response.status == "success") {
            response.data.date = momentTZ.tz(response.data.date, this.base.locale_timezone)

						if(response.data.cutoff_group == null){
							response.data.member1.registration_event.url_image = response.data.member1.registration_event.url_image != null ? response.data.member1.registration_event.url_image : NoImage
							response.data.member2.registration_event.url_image = response.data.member2.registration_event.url_image != null ? response.data.member2.registration_event.url_image : NoImage
						}
          this.$set(this.arr_match, this.selected_index, response.data)
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_data() {
      var counter = 0
      for(let factor of this.arr_factor){
        if(!factor)
          break
        counter++
      }

      if(counter == this.arr_factor.length)
        window.$('#please_wait_modal').modal('show')
      var date = this.date != '' && this.date != null ? momentTZ(this.date, 'DD/MM/YYYY') : ''
      var response = await this.base.request(this.base.url_api + '/match/all?rel_type=simple&api_type=not_tournament&date=' + (date != '' ? date.format('YYYY-MM-DD') : '') + '&event_category_sport_id=' + this.$route.query.id)
      this.$set(this.arr_factor, 0, true)
      if(counter == this.arr_factor.length)
        window.$('#please_wait_modal').modal('hide')

      if (response != null) {
        if (response.status == "success") {
          for (let data of response.data) {
            data.date = momentTZ.tz(data.date, this.base.locale_timezone)

						if(data.cutoff_group == null){
							data.member1.registration_event.url_image = data.member1.registration_event.url_image != null ? data.member1.registration_event.url_image : NoImage
							data.member2.registration_event.url_image = data.member2.registration_event.url_image != null ? data.member2.registration_event.url_image : NoImage
						}
          }
          this.arr_match = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/app.scss";

@include media-breakpoint-up(md) {
  .text-md-normal {
    font-size: 1rem;
  }
  .score-container{
    height: 5rem;
  }

  .member-container {
    p {
      font-size: 1rem;
    }

    .match-image-container {
      height: 5rem;

      img {
        height: 5rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .text-small {
    font-size: 0.875rem;
  }
  .score-container{
    height: 4rem;
  }

  .member-container {
    p {
      font-size: 0.875rem;
    }

    .match-image-container {
      height: 4rem;

      img {
        height: 4rem;
      }
    }
  }
}

.member-container {
	width: 20rem;
}

.match-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto 1fr;
  gap: 1rem;
}

.competition-detail-venue-court {
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

.cursor-pointer {
  cursor: pointer;
}
.selector-hover:hover{
  background-color: #eaeaea;
}

// .form-control{
//   background-position: right .75rem center;
// }</style>
